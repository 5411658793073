
import React,{useState} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import Appbar from '../components/Appbar'
import Drawe from '../components/Drawe'
import AddProduct from '../views/AddProduct'
import Car from '../views/Car'
import Category from '../views/Categoriy'
import Dashboard from '../views/Dashboard'
import Offer from '../views/Offer'
import Products from '../views/Products'
import Profile from '../views/Profile'
import  Store  from '../views/Store'
import Support from '../views/Support'
import AddCategory from '../views/AddCategory'
import UploadCategory from '../views/UploadCategory'
import Maintenance from '../views/Maintenance'
import Ventas from '../views/Ventas'
import AddAdmin from '../views/AddAdmin'
const AdminRoutes = () => {
const [ open, setOpen ] = useState( false )
    return (
        <div>
        <Appbar
        open={open}
        setOpen={setOpen}>
        </Appbar>
        
        <Switch>
            <Route exact path="/dashboard" component={Dashboard}></Route>
            <Route exact path="/profile" component={Profile}></Route>
            <Route exact path="/category" component={Category}></Route>
            <Route exact path="/store" component={Store}></Route>
            <Route exact path="/product" component={Products}></Route>
            <Route exact path="/car" component={Car}></Route>
            <Route exact path="/support" component={Support}></Route>
            <Route exact path="/offer" component={Offer}></Route>
            <Route exact path="/addproduct" component={AddProduct}></Route>
            <Route exact path="/addcategory" component={AddCategory}></Route>
            <Route exact path="/updatecategory/:id" component={UploadCategory}></Route>
            <Route exact path="/maintenance" component={Maintenance}></Route>
            <Route exact path="/addadmin" component={AddAdmin}></Route> 
            <Route exact path="/ventas" component={Ventas}></Route>

            <Redirect to="/dashboard"></Redirect>
        </Switch>

        <Drawe
        open={open}
        setOpen={setOpen}></Drawe>
     </div>
    )
}



export default AdminRoutes
