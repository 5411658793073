import React from 'react'
import {
    Grid,
    Typography,
    IconButton,
  } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { useHistory, useParams } from 'react-router-dom';
import { useCategory } from '../hooks/useCategory';
import CategoryUpdate from '../components/CategoryUpdate';
const UploadCategory = (props) => {

    const history =useHistory();


    const {categories} = useCategory()

    const idcategories = categories.filter(categorie => categorie.IdCategoria === props.id)


    return (
      <main style={{ maxHeight: "100vh",margin: "5px 10px 15px 10px" }}>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <IconButton color="primary" onClick={()=>{
                history.push("/category")}}>
          <CancelIcon color="primary" style={{ fontSize: "32px" }} />
        </IconButton>
      </Grid>

      <Typography
        style={{
          fontSize: "30px",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Actualizar Categoria
      </Typography>


      <Typography style={{ fontSize: "24px", marginBottom: "40px",marginLeft:'150px' }}>
          {" "}
          Datos de Categoría
        </Typography>

        {
          idcategories.map(item =>{
            return <CategoryUpdate nombre={item.Nombre} portada={item.Portada} condicion={item.Condicion} id={item.IdCategoria}></CategoryUpdate>
          })
        }
    </main>
    )
}

export default UploadCategory
