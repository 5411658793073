import {useEffect, useState, useRef} from 'react'
import axios from 'axios';
import {url} from '../data/url'

export const useOffer = () => {

    const isMounted =useRef(true)
    const [offer, setOffer] = useState({
        ofertas:[]
    })
    useEffect(() => {
        
        return () => {
            isMounted.current =false
        }
    }, [])
    useEffect(  () => {

        axios
        .get(`${url}admin/offers`)
        .then(res =>{
            if(isMounted.current){
                console.log(res)
                setOffer(
                    {
                      ...offer,
                      ofertas:res.data
                    })
            } 
        })
        .catch(err =>{
          // console.log(err)
        })
        
  }, [])
  
  return offer
}


