import React from "react";
import {
  Button,
  Grid,
  InputBase,
  Typography,
} from "@material-ui/core";
import { makeStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CategoryCard from "../components/CategoryCard";
import { useHistory } from 'react-router-dom';
import CategoryHeader from "../components/CategoryHeader";
import { useCategory } from "../hooks/useCategory";
import ButtoonAddCategory from '../components/ButtonAddCategory'

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#D8D8D8",
    "&:hover": {
      backgroundColor: "#d6d2d2",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(6),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#02AAB0",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  btn_category: {
    backgroundColor: "#02AAB0",
    color: "white",
    fontSize: "10px",
    borderRadius: "20px",
    width: "150px",
    marginRight: "75px",
    "&:hover": {
      backgroundColor: "#00858a",
    },
    "@media (max-width: 600px)": {
      width: "95px",
      fontSize: "8px",
    },
  },
 
}));
const Category = () => {
  const classes = useStyles();
  const history =useHistory();
  const {categories} = useCategory()
  console.log(categories)
  return (
    <main className={classes.content}>

      <Grid container style={{ height: "85px" }}>
        <Grid item xs={6} sm={6} style={{ marginTop: "10px" }}>
          <Typography variant="h5"> Categorias </Typography>
        </Grid>

      </Grid>

      <Grid container style={{marginBottom:'35px'}}>
        <Grid
          style={{
            display: "flex",
            flexWrap: "wrap",
            boxSizing: "border-box",
            width: "50%",
          }}
        >
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Grid>

        <Grid
          style={{
            justifyContent: "flex-end",
            paddingRight: "50px",
            display: "flex",
            flexWrap: "wrap",
            boxSizing: "border-box",
            width: "50%",
          }}
        >
          <ButtoonAddCategory></ButtoonAddCategory>

          {/* <Button className={classes.btn_category} onClick={()=>{
                history.push("/addcategory")
              }}>Añadir Categoria</Button> */}
        </Grid>
      </Grid>

            <CategoryHeader></CategoryHeader>
           {
             categories.map(item =>{
                return (<CategoryCard jey={item.IdCategoria} id_Cat={item.IdCategoria} nombre_producto={item.Nombre} portada_producto={item.Portada} condicion={item.Condicion}></CategoryCard>)
             })
           }
            
    </main>
  );
};
export default Category;
