import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { useHistory } from "react-router-dom";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import InputDateInit from "./InputDateInit";
import InputFinish from "./InputFinish";

const CssTextField = withStyles({
  root: {
    color: "white",
    width: "500px",
    backgroundColor: "#F2F2F2",

    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#02AAB0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#02AAB0",
        border: "2px solid #02AAB0",
        borderRadius: "7px !important",
      },
      "&:hover fieldset": {
        borderColor: "#02AAB0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#02AAB0",
      },
    },
  },
})(TextField);
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    width: "250px",
    color: "#02AAB0",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#02AAB0",
      borderWidth: "3px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#02AAB0",
      borderWidth: "3px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#02AAB0",
      borderWidth: "3px",
    },
  },
  inputfile: {
    padding: "30px",
    // backgroundColor:'rgba(47, 176, 193, 0.51);',
    color: "black",
    borderRadius: "10px",
    cursor: "pointer",
    border: "none",
    outline: "none",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  btn_category: {
    backgroundColor: "#02AAB0",
    color: "white",
    fontSize: "18px",
    borderRadius: "20px",
    width: "200px",
    "&:hover": {
      backgroundColor: "#00858a",
    },
    "&:active": {
      position: "relative",
      top: "2px",
    },
  },
}));

const ButtonUpdateOffer = (props) => {
  const { offer,setoffer,handleactualizar } = props;

  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 
  const handledescuento = (e) =>{
    setoffer({
      ...offer,
      [e.target.name]:e.target.value
    })
  }


  return (
    <>
      <IconButton onClick={handleClickOpen} color="primary">
        <LocalOfferIcon color="primary" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogContent>
          <div className={classes.content}>
            <Grid
              container
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Grid item>
                <IconButton color="primary" onClick={handleClose}>
                  <CancelIcon
                    onClick={handleClose}
                    color="primary"
                    style={{ fontSize: "30px" }}
                  ></CancelIcon>
                </IconButton>
              </Grid>
            </Grid>
            <Grid container style={{ display: "flex", width: "100%" }}>
              <Grid item sm={12}>
                <Typography variant="h4" style={{ textAlign: "center" }}>
                  Actualizar Oferta
                </Typography>
              </Grid>
            </Grid>
            <div style={{ padding: "25px" }}>
              <Typography>Nombre del producto</Typography>
              <CssTextField
                variant="outlined"
                margin="normal"
                value={offer.descuento}
                name="descuento"
                style={{ width: '100%' }}
                label="Descuento"
                placeholder="Ingrese el nuevo descuento"
                onInput={(e) => onlyNumbers(e)}
                inputProps={{ maxLength: 3 }}
                onChange={handledescuento}
              />


              <form >
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <Typography>Fecha Inicial</Typography>
                  <InputDateInit offer={offer} setoffer={setoffer}></InputDateInit>
                </div>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <Typography>Fecha Final</Typography>
                  <InputFinish offer={offer} setoffer={setoffer}></InputFinish>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button className={classes.btn_category} onClick={handleactualizar}>
                    Actualizar
                  </Button>
                </div>
                
              </form>


            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ButtonUpdateOffer;
