import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputBase, NativeSelect, Snackbar, TextField, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import FormProduct from '../components/FormProduct';
import { useHistory } from 'react-router-dom';
import CachedIcon from '@material-ui/icons/Cached';
import { useProducts } from '../hooks/useProducts';
import Axios from 'axios'
import {url} from '../data/url'
import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme)=>({
  content:{
      flexGrow:1,
      paddingBottom:theme.spacing(4)
    },
    formControl:{
      width:'250px',
      color:'#02AAB0',
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#02AAB0",
        borderWidth:'3px'
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#02AAB0",
        borderWidth:'3px'
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#02AAB0",
        borderWidth:'3px'
      },
  },
  inputfile:{
      padding:'30px',
      color:'black',
      borderRadius:'10px',
      cursor:'pointer',
      border:'none',
      outline:'none'

  },
  btn_category: {
    backgroundColor: "#02AAB0",
    color: "white",
    fontSize: "18px",
    borderRadius: "20px",
    width: "200px",
    "&:hover": {
      backgroundColor: "#00858a",
    },
    "&:active":{
      position: 'relative',
      top:'2px',
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const ButtonUpdateProducts = ({id}) => {

  const {products} = useProducts()
  const [addproduct,setAddproduct] = useState({})

  useEffect(()=>{

    const producto = products.find(products => products.IdProducto === id)

    console.log(producto)

    setAddproduct(producto)

  },[products])


   const handleChange = (e) =>{
    setAddproduct({
      ...addproduct,
      [e.target.name]:e.target.value
    })
   }

    const classes = useStyles()

    
      const [open, setOpen] = React.useState(false);
      const [open2, setOpen2] = React.useState(false);
      const [open3, setOpen3] = React.useState(false);

       const handleClickOpen = () => {
         setOpen(true);
       };
    
       const handleClose = () => {
         setOpen(false);
       };

    

    const [message,setMessage] = useState({
      type:'',
      message1:'',
      message2:'',
      status:false
    })

    const timer = useRef()

    const history = useHistory()

    const handleActualizarProd = ( ) =>{
      Axios
      .put(`${url}admin/products`,{
        IdProducto: id,
        stock_producto: addproduct.stock_producto,
        nombre_producto: addproduct.nombre_producto,
        precio_producto: addproduct.precio_producto,
        avatar_producto: addproduct.avatar_producto,
        descripcion_producto: addproduct.descripcion_producto
      })
      .then(res => {
        console.log(res)
        if(res.data.success === 1) {
          setMessage({
            ...message,
            type:'success' ,
            message1:'Exitoso',
            message2:res.data.message,
            status:true
          })
          setOpen3(true)

          setOpen2(true)
          timer.current = setTimeout(() => {
            
            history.push('/product')
          }, 1500);
         }else{
          setMessage({
            ...message,
            type:'warning' ,
            message1:'Rechazado',
            message2:res.data.message,
            status:true
          })
          setOpen3(true)
          timer.current = setTimeout(() => {
            setMessage({
              ...message,
              status:false
            })
            setOpen3(false)
          }, 2000);
         }
      })
    }

    

    return (
      <>
        <IconButton onClick={handleClickOpen} color="primary">
          <CachedIcon color="primary" />
        </IconButton>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogContent>
            <div className={classes.content}>
              <Grid
                container
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item>
                  <IconButton color="primary" onClick={handleClose}>
                    <CancelIcon
                      onClick={handleClose}
                      color="primary"
                      style={{ fontSize: "30px" }}
                    ></CancelIcon>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container style={{ display: "flex", width: "100%" }}>
                <Grid item sm={12}>
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    Actualizar Producto
                  </Typography>
                </Grid>
              </Grid>
              <FormProduct
                addproduct={addproduct}
                setAddproduct={setAddproduct}
                handleChange={handleChange}
                add={false}
              ></FormProduct>
              <Grid
                item
                align="center"
                style={{ marginTop: "25px", marginBottom: "15px" }}
              >
                <Button
                  className={classes.btn_category}
                  onClick={handleActualizarProd}
                >
                  Actualizar
                </Button>
                <Backdrop
                  className={classes.backdrop}
                  open={open2}
                  onClick={handleClose}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
        {message.status && (
          <Snackbar open={open3} autoHideDuration={6000}>
            <Alert onClose={handleClose} severity={message.type}>
              {message.message2}
            </Alert>
          </Snackbar>
        )}
      </>
    );
}

export default ButtonUpdateProducts;
