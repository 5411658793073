import Axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'

export const useSales = (url) => {

const [sales, settSales] = useState([])
    
    useEffect(() => {
        Axios
        .get(`${url}ecommerce/ventas`)
        .then(res=>{
            console.log(res)
            settSales(res.data)
        })
    }, [url])

    return sales
}


