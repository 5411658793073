import { Button, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import VentaCompletada from '../components/VentaCompletada'

import TableVentaCompletada from '../components/TableVentaCompletada'
import { useState } from 'react';
import { useSales } from '../hooks/useSales';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';  
import {url} from '../data/url'
import Axios from 'axios';

import VentaFallida from '../components/TableVentaFallida'


const useStyles=makeStyles((theme)=>({
    content:{
        padding:theme.spacing(5),
        "@media(max-width:600px)":{
        padding:theme.spacing(3),
        }

      },
      section_btn:{
          display: 'flex',
          justifyContent: 'center',  
          marginBottom:'20px',
      },
      icon:{
        margin:'5px 15px 5px 15px',
        "@media(max-width:600px)":{
            margin:'0px',
            }
      },
      btn:{
         margin:'0px 15px 0px 15px',
         "@media(max-width:600px)":{
            margin:'0px',
            }
      }
}));
const Ventas = () => {
    const classes=useStyles();
    const [complete, setComplete] = useState(true)

    const handlecomplete = () =>{
        setComplete(false)
    }
    const handleIncomplete = () =>{
        setComplete(true)
    }

    const [sales, setSales] = useState([])

    const [salesfailed, setSalesfailed] = useState([])
    
    useEffect(() => {
        Axios
        .get(`${url}ecommerce/ventas`)
        .then(res=>{
            // console.log(res)
            setSales(res.data)
        })
        
        
    },[])
  

    useEffect(() => {
        Axios
        .get(`${url}admin/ventasfallidas`)
        .then(res=>{
            
            setSalesfailed(res.data)
        })

        
    },[])

    return (
        <div className={classes.content}>
            <div className={classes.section_btn}>
            <Button color="primary" className={classes.btn} onClick={handleIncomplete}><AssignmentTurnedInIcon className={classes.icon}/>Ventas Completadas</Button> 
            <Button color="primary" className={classes.btn} onClick={handlecomplete}><AssignmentLateIcon className={classes.icon}/>Ventas Fallidas</Button> 
            </div>


            {
                complete ?
                <TableVentaCompletada sales={sales} setsales={setSales}></TableVentaCompletada>
                :
                <VentaFallida salesfailed={salesfailed } setSalesfailed={setSalesfailed}/>
            }


        </div>
    )
}

export default Ventas
