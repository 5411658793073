import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import {url} from '../data/url'
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  main: {
    margin: "0px 150px 0px 150px",
    width: "70%",
  },
  title_mant: {
    fontSize: "35px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "25px",
  },
  grid_container: {
    width: "500px",
    margin: "auto",
    marginBottom:'35px',
  },
  grid_item: {
    textAlign: "center",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grid_item_izq: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
}));

const Maintenance = ({condicion}) => {

 
  // const {condicion_abierto} = config_tienda
  console.log(condicion )
  const classes = useStyles();

  const [mante, setmante] = useState(condicion)
  console.log(mante);

  const [catput, setCatput] = useState({
    condicion_abierto:mante == true ? "0" : "1"
  })

  // console.log(catput.condicion_abierto);
  const handleChange = (event) => {
    setmante(event.target.checked);
    setCatput({
      ...catput,
      condicion_abierto:mante == true ? "1" : "0"
    })


    Axios
    .put(`${url}admin/categories`,catput)
    .then(res=>{
      console.log(res)
    })
    
  };




  return (
    <main className={classes.main}>
      <Grid container className={classes.grid_container}>
        <Grid item xs={6} className={classes.grid_item}>
          <Typography style={{fontSize:'25px'}}>Mantenimiento</Typography>
        </Grid>
        <Grid item xs={6} className={classes.grid_item}>
          <Typography  style={{fontSize:'25px'}}> OFF/ON</Typography>
        </Grid>
      </Grid>

       {/* ====================================================
       TIENDA VIRTUAL 
       ==================================================== */}
      <Grid container className={classes.grid_container}>
        <Grid item xs={6} className={classes.grid_item_izq}>
          <Typography>TIENDA VIRTUAL</Typography>
        </Grid>
        <Grid item xs={6} className={classes.grid_item}>
        <FormGroup>
          <FormControlLabel
            style={{ margin: "auto" }}
            control={
              <Switch
                // checked={mante}
                // onChange={handleChange}
                aria-label="category switch"
                color="primary"              
                checked={mante}
                onChange={handleChange}
              />}
            />
        </FormGroup>
        </Grid>
      </Grid>
    </main>
  );
};

export default Maintenance;
