
const AdminReducer = (state = {}, action) => {
    
    switch (action?.type) {
        case 'login':
            return{
                ...state,
                isLogged: true
                
            }
        case 'logout':
                return{
                    ...state,
                    isLogged: false
                    
            }   
            default :
            break;
    }
}
export default AdminReducer
