import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import {makeStyles} from '@material-ui/core/styles'
import OfferCard from '../components/OfferCard'
import { useOffer } from '../hooks/useOffer';
const useStyles = makeStyles((theme)=>({
    content:{
        flexGrow:1,
        padding:theme.spacing(5)
      },
      button:{
          borderRadius:'10px',
          padding:'20px',
          margin:'0px 10px'
      },
      formControl:{
          width:'250px',
          color:'#02AAB0',
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#02AAB0",
            borderWidth:'3px'
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#02AAB0",
            borderWidth:'3px'
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#02AAB0",
            borderWidth:'3px'
          },
      },
      search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#D8D8D8',
        '&:hover': {
          backgroundColor: '#D8D8D8',
        },
        width: '300px',
        marginLeft:'10px',
        height:'35px',
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {

        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '80%',
        display:'flex',
        alignItems:'center'
      },

}))
const Offer = () => {
  const [idcat, setidcat] = useState('')
  const {ofertas} = useOffer()
  const handleChange = (e) =>{
    setidcat(e.target.value)
  }
    const classes=useStyles()
    return (
        <div className={classes.content}>
            <Typography variant="h3">Ofertas</Typography>
            <br></br>
            <Grid container>
          {/* <Grid item sm={6} xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <Typography
                color="secondary"
                variant="subtitle1"
                style={{ fontWeight: "700" }}
              >
                Agregar Oferta
              </Typography>
            </Button>
          </Grid> */}

          <Grid
            item
            style={{ 
              display: "flex", 
              alignItems: "center",justifyContent:'flex-end'}}
            sm={12}
            xs={12}
          >
          </Grid>
        </Grid>
             <Box
        display="flex"
        flexWrap="nowrap"
        p={1}
        mt={5}
        bgcolor="background.paper"
        css={{ width:'100%' ,  
        justifyContent:'space-around',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px' }}
        >
        <Box p={1} mx={3} style={{textAlign:'center'}} width='100px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>ID</Typography>
        </Box>
        <Box p={1} mx={5} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Nombre</Typography>
        </Box>
        <Box p={1} mx={5} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Imagen</Typography>
        </Box>
        <Box p={1} mx={5} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Descuento</Typography>
        </Box>
        <Box p={1} mx={5} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Fecha Inicio</Typography>
        </Box>
        <Box p={1} mx={5} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Fecha Fin</Typography>
        </Box>
        <Box p={1} mx={5} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Actualizar</Typography>
        </Box>
      </Box>
          {
            ofertas.map(item =>{
              return  ( <OfferCard IdOffer={item.IdProducto} nombre_offer={item.nombre_producto} avatar_offer={item.avatar_producto} descuento={item.descuento} fecha_inicio={item.fecha_inicio} fecha_fin={item.fecha_fin}></OfferCard>)
            })
          }
     

        </div>
    )
}

export default Offer
