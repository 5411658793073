import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputBase, NativeSelect, TextField, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Axios from 'axios'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import {url} from '../data/url'
import CreateIcon from '@material-ui/icons/Create';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';
const ButtonUpdateSale = (props) => {
    const history = useHistory()
    const {id,status} = props
    // const {id,status,idmoto} = props

    // console.log(idmoto)
    // console.log(id)
    
      const [open, setOpen] = React.useState(false);

       const handleClickOpen = () => {
         setOpen(true);
       };
    
       const handleClose = () => {
         setOpen(false);
       };

       const BootstrapInput = withStyles((theme) => ({
         root: {
           "label + &": {
             marginTop: theme.spacing(3),
           },
           '& .MuiNativeSelect-select:focus':{
             backgroundColor:'white'
           }
         },
         input: {
           borderRadius: 4,
           position: "relative",
           backgroundColor: "#F2F2F2",
           border: "1px solid #02AAB0",
          fontSize: 15,
           color: "#6e6e6e",
           padding: "10px 26px 10px 12px",
           "@media(max-width:600px)":{
             fontSize:12,
           }
      
        },
      }))(InputBase);


    const handleEntrega = () =>{
      Axios
      .put(`${url}ecommerce/ventas`,{
          id: id
      })
      .then(res => {
        console.log(res)
        setTimeout(()=>{
          // history.push('/ventas')
          window.location.reload();
        },1000) 
      }) 
    }
    return (
      <>
        {status === "entregado" ? null : (
          <>
            <IconButton onClick={handleClickOpen} color="primary"><SettingsIcon color="primary"></SettingsIcon></IconButton>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title" style={{color:'black',fontWeight:'bold'}}>
                Opciones de Entrega
              </DialogTitle>
              <DialogContent>
               
                <DialogActions>
                  <Button
                    onClick={handleEntrega}
                    variant="contained"
                    style={{color:'white',fontWeight:'bold',backgroundColor:'blue'}}
                  >
                    Actualizar estado a: ENTREGADO
                  </Button>

                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    style={{color:'white'}}
                  >
                    Cancelar
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </>
        )}
      </>
    );
}

export default ButtonUpdateSale
