import React from 'react'

const Profile = () => {
    return (
        <div style={{color:'black'}}>
            profile
        </div>
    )
}

export default Profile
