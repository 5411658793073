import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import {url} from '../data/url'
import CategoryComponent from '../components/CategoryComponent'
import Axios from "axios";
const useStyles = makeStyles((theme) => ({
    btn_category: {
        backgroundColor: "#02AAB0",
        color: "white",
        fontSize: "18px",
        borderRadius: "20px",
        width: "200px",
        "&:hover": {
          backgroundColor: "#00858a",
        },
      },


}));

const AddCategory = () => {
  const classes = useStyles();
  const history =useHistory();

  const [addcaegory, setAddcategory] = useState({
    Nombre: '',
    Portada:'',
    Condicion: 1
  })

  const handleCategory =  () =>{
    Axios
    .post(`${url}admin/categories`,addcaegory)
    .then(res =>{
       console.log(res.data);
    })
  }
  console.log(addcaegory)
  return (
    <main style={{ minHeight: "100vh", margin: "20px 100px 25px 100px" }}>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <IconButton color="primary" onClick={()=>{
                history.push("/category")}}>
          <CancelIcon color="primary" style={{ fontSize: "32px" }} />
        </IconButton>
      </Grid>

      <Typography
        style={{
          fontSize: "30px",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Agregar Categoria
      </Typography>


      <Typography style={{ fontSize: "24px", marginBottom: "40px",marginLeft:'150px' }}>
          {" "}
          Datos de Categoría
        </Typography>

     <CategoryComponent setAddcategory={setAddcategory} addcaegory={addcaegory}></CategoryComponent>

      <Grid item align="center" style={{marginTop:'45px',marginBottom:'25px'}}>
      <Button className={classes.btn_category} onClick={handleCategory}>Agresgar</Button>
      </Grid>

    </main>
  );
};

export default AddCategory;
