import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Snackbar,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Axios from "axios";
import { url } from "../data/url";
import firebase from "firebase";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import ImageIcon from '@material-ui/icons/Image';

  const CssTextField = withStyles({
    root: {
      color: "white",
      width: "500px",
      backgroundColor: "#F2F2F2",
  
      "& label.Mui-focused": {
        color: "black",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#02AAB0",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#02AAB0",
          border: "2px solid #02AAB0",
          borderRadius: "7px !important",
        },
        "&:hover fieldset": {
          borderColor: "#02AAB0",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#02AAB0",
        },
      },
    },
  })(TextField);
  
  const useStyles = makeStyles((theme) => ({
      btn_category: {
          backgroundColor: "#02AAB0",
          color: "white",
          fontSize: "18px",
          borderRadius: "20px",
          width: "200px",
          "&:hover": {
            backgroundColor: "#00858a",
          },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
          },
  })); 

const CategoryUpdate = (props ) => {
    const classes = useStyles();


    const [addcaegory, setAddcategory] = useState({
      IdCategoria: props.id,
      Nombre: props.nombre,
      Portada: props.portada,
      Condicion: props.condicion,
    });

    const handleChange = ({ target }) => {
        setAddcategory({
        ...addcaegory,
        [target.name]: target.value,
      });
    };

    const [category, setCategory] = React.useState(addcaegory.Condicion === "1" ? true : false);

    const handleChangeSwitch = (event) => {
      setCategory(event.target.checked);
      setAddcategory({
          ...addcaegory,
          Condicion : category === true ? "0" :"1"
      })
    };

    console.log(category);
    console.log(addcaegory);

    const [image, setimage] = useState(null);

    const handleImage = (e) => {
      setimage(e.target.files[0]);
    };

    const [imagefirebase, setimagefirebase] = useState(null);

    const handleupload = () => {
      const storageRef = firebase
        .storage()
        .ref(`/portadas/${image.name}`)
        .put(image);
      storageRef.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          firebase
            .storage()
            .ref("portadas")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setimagefirebase(url);
              setAddcategory({
                ...addcaegory,
                Portada: url,
              });
            });
        }
      );
    };

    const timer = useRef()

    const history = useHistory()

    const [message,setMessage] = useState({
      type:'',
      message1:'',
      message2:'',
      status:false
    })

    const [open2, setOpen2] = React.useState(false);

    // const handleCategory = () => {
    //   Axios.put(`${url}admin/categories`, addcaegory).then((res) => {
    //     console.log(res.data);
        
    //   });
    //   setOpen(!open);
    //   timer.current = setTimeout(()=>{
    //     history.push("/category")
    //   },2500)
    // };
    const handleCategory =  () =>{
      Axios
      .put(`${url}admin/categories`,addcaegory)
      .then(res =>{
         console.log(res.data);
         if(res.data.success === 1) {
          setMessage({
            ...message,
            type:'success' ,
            message1:'Exitoso',
            message2:res.data.message,
            status:true
          })
          setOpen2(true)

          setOpen(true)
          timer.current = setTimeout(() => {
            
            history.push('/category')
          }, 1500);
         }else{
          setMessage({
            ...message,
            type:'warning' ,
            message1:'Rechazado',
            message2:res.data.message,
            status:true
          })
          setOpen2(true)
          timer.current = setTimeout(() => {
            setMessage({
              ...message,
              status:false
            })
            setOpen2(false)
          }, 2000);
         }
      })
    }

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    return (
        <main style={{margin: "20px 5px 25px 150px" }}>
      <form>
        <CssTextField
          variant="outlined"
          margin="normal"
          value={addcaegory.Nombre}
          name="Nombre"
          type="text"
          onChange={handleChange}
          label="Nombre de la categoria"
          placeholder="Ingrese el nombre para la categoria"
        />

        <Typography
          style={{ fontSize: "18px", marginTop: "30px", marginBottom: "25px" }}
        >
          Subir Portada
        </Typography>

        <Grid
          item
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginBottom: "45px",
          }}
          spacing={2}
        >
          <input 
          type="file"
          accept="image/jpeg"
          id="real-file"
          hidden="hidden"
          onChange={handleImage}
          ></input>
          <Button
            type="button"
            variant="contained"
            color="primary"
            id="custom-button"
            className={classes.inputfile}
            style={{ display: "flex", alignContent: "center" }}
            onClick={() => {
              const realFileBtn = document.getElementById("real-file");
              realFileBtn.click();
            }}
          > <ImageIcon
          color="secondary"
          style={{ marginRight: "10px" }}
        ></ImageIcon>
            
            <Typography color="secondary" style={{ fontWeight: "700" }}>
              Escoger Imagen
            </Typography>
          </Button>
          <Typography
            id="custom-text"
            style={{ padding: "30px", marginLeft: "10px" }}
          >
            Ningun archivo seleccionado
          </Typography>

          {
            image !== null &&
            
            <Button onClick={handleupload} 
            type="button"
            className={classes.inputfile} 
            color="primary" 
            variant="contained">
              <CloudUploadIcon
              color="secondary"
              style={{ marginRight: "10px" }}
            ></CloudUploadIcon><Typography color="secondary" style={{ fontWeight: "700" }}>
              Subir Imagen
          </Typography></Button>
          }
         
        
        </Grid>
       {
          imagefirebase !== null &&
          <div style={{display:'flex',justifyContent:'center'}}>
            <img src={imagefirebase} height="200" alt="Image preview..." id="imgra"></img>  
          </div>
           
        }
        
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={6}>
            <Typography >Habilitar/Ocultar Categoria</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <FormGroup>
              <FormControlLabel
                style={{ margin: "auto" }}
                control={
                  <Switch
                    checked={category}
                    onChange={handleChangeSwitch}
                    aria-label="category switch"
                    color="primary"
                  />
                }
                //   label={auth ? 'Logout' : 'Login'}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item align="center" style={{marginTop:'45px',marginBottom:'25px'}}>
        <Button className={classes.btn_category} onClick={handleCategory}>Actualizar</Button>
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
        </Backdrop>
        </Grid>
        {
          message.status &&
          <Snackbar open={open2} autoHideDuration={6000} >
          <Alert onClose={handleClose} severity={message.type}>
          {message.message2}
          </Alert>
        </Snackbar>
        }
      </form>
    </main>
    )
}

export default CategoryUpdate
