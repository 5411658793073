import { Backdrop, Box, IconButton, ListItem, TextField, Typography } from '@material-ui/core'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ButtonUpdateProducts from './ButtonUpdateProducts';
import { useState, useRef} from 'react';
import { makeStyles } from '@material-ui/core'
import { useEffect } from 'react';
import Axios from 'axios'
import {url} from '../data/url'
import CircularProgress from '@material-ui/core/CircularProgress';  
import { useHistory } from 'react-router-dom';
import ProductsDescription from './ProductsDescription'

const useStyles = makeStyles((theme) => ({

  stock:{
    textAlign:'center',
    borderRadius:'8px',
    outline:'none',
    border:'1px solid grey'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const ProductoCard = ({item}) => {

  const classes = useStyles()

  const [open, setOpen] = React.useState(false);

  const [stock, setstock] = useState(parseInt(item.stock_producto))

  console.log(stock)
  const handleChange = (e) =>{
    setstock(e.target.value)
  }

  const increment = ( ) => {
    setstock(stock + 1)
  }

  const decrement = ( ) => {
    setstock(stock - 1)
  }

  useEffect(() => {

    const data ={
      IdProducto: item.IdProducto,
      stock_producto: stock
    }
    Axios
      .put(`${url}admin/products`,data)
      .then(res => {
        console.log(res)
      })
  }, [stock])

  const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') };

  const history = useHistory()

  const timer = useRef()

  const deleteproduct = () =>{

    const url1 = url
    async function postData(url = `${url1}admin/products`, data = {
      IdProducto:item.IdProducto
    }) {

      const response = await fetch(url, {
        method: 'DELETE', 
        mode: 'cors', 
        cache: 'no-cache', 
        credentials: 'same-origin', 
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', 
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data) 
      });
      return response.json(); 
    }

    postData()
      .then(data => {
        console.log(data); 
        setOpen(true)
        timer.current = setTimeout(()=>{
          history.push("/product")
        },2500)
        
      });
          
   
}

  const [open3, setOpen3] = React.useState(false);

    return (
        <Box
        display="flex"
        flexWrap="nowrap"
        p={1} 
        mt={5}
        bgcolor="background.paper"
        css={{ width:'100%' ,  
        justifyContent:'space-around',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px' }}
        >
        <Box p={1} mx={3} display='flex' width='50px' style={{justifyContent:'center',alignItems:'center'}}>
        <Typography variant="body2" style={{fontWeight:'700'}}>{item.IdProducto}</Typography>
        </Box>
        <Box p={1}  mx={4} display='flex' width='150px' style={{justifyContent:'center',alignItems:'center'}}>
        <Typography variant="body2" style={{fontWeight:'700'}}>{item.nombre_producto}</Typography>
        </Box>
        <Box p={1}  mx={5} display='flex' width='250px' style={{justifyContent:'center',alignItems:'center'}}>
        <ProductsDescription descripcion={item.descripcion_producto}></ProductsDescription>
        </Box>
        <Box p={1}  mx={3}  display='flex' width='150px' style={{justifyContent:'center',alignItems:'center'}}>
        <Typography variant="body2" style={{fontWeight:'700',display:'flex',justifyContent:'center'}}><img src={item.avatar_producto} alt="fotoproduct"style={{width:'70%'}}></img></Typography>
        </Box>
        <Box p={1}  mx={4} display='flex' width='200px' style={{justifyContent:'center',alignItems:'center'}}>
        <Typography variant="body2" style={{fontWeight:'700'}}>{item.precio_producto}</Typography>
        </Box>
        <Box p={1} mx={3} width='280px' display='flex' style={{justifyContent:'center',alignItems:'center'}}>
        <IconButton color="primary" onClick={decrement}>
        <RemoveCircleIcon color="primary"/>
        </IconButton>
        <TextField 
        className={classes.cuadrotexto}
        variant="outlined"
        value={stock}
        onChange={handleChange}
        autoComplete="nope"
        onInput={(e) => onlyNumbers(e) }
        ></TextField>
        <IconButton color="primary" onClick={increment}>
        <AddCircleIcon color="primary"/>
        </IconButton>
        </Box>
        <Box p={1}  mx={5} display='flex' width='150px' style={{justifyContent:'center',alignItems:'center'}}>
        <ButtonUpdateProducts id={item.IdProducto}></ButtonUpdateProducts>
        <IconButton color="primary" onClick={deleteproduct}>
        <DeleteIcon color="primary">
        </DeleteIcon>
        </IconButton>
        <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
        </Backdrop>
        </Box>
      </Box>
    )
}

export default ProductoCard
