import React from 'react'

const Support = () => {
    return (
        <div>
            support
        </div>
    )
}

export default Support
