import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import {  makeStyles,withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Button, IconButton, TextField, Typography } from "@material-ui/core";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';  
import Axios from "axios";
import {url} from '../data/url'
import SearchIcon from '@material-ui/icons/Search';
import LoopIcon from '@material-ui/icons/Loop';
import VentaCompletada from "./VentaCompletada";
import PopoverTabel from "./PopoverTabel";
import ButtonUpdateSale from "./ButtonUpdateSale";
import ButtonUpdateDelivery from "./ButtonUpdateDelivery";
import StatusSales from "./StatusSales";
import ProductsSale from "./ProductsSale";
import PopoverInfoSale from "./PopoverInfoSale";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#02AAB0',
    color: 'white',
    border:'1px solid white',
  },
  body: {
    fontSize: 12,
    backgroundColor: '#e8e8e8',
    border:'1px solid #cac7c7a8',
  },

}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'theme.palette.action.hover',
    },
  },
}))(TableRow);

function createData(id,dni_ruc,cliente,direccion,distrito,celular,fecha,cant,total,estado,comentario,repartidor,IdVenta) {
  return { id,dni_ruc,cliente,direccion,distrito,celular,fecha,cant,total,estado,comentario,repartidor,IdVenta};
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id",numeric: true,disablePadding: false,label: "ID"},
  { id: "dni_ruc", numeric: true, disablePadding: false, label: "DNI/RUC" },
  { id: "cliente", numeric: false, disablePadding: false, label: "CLIENTE" },
  { id: "direccion", numeric: false, disablePadding: false, label: "DIRECCION" },
  { id: "distrito", numeric: false, disablePadding: false, label: "DISTRITO" },
  { id: "celular", numeric: true, disablePadding: false, label: "CELULAR" },
  { id: "fecha", numeric: false, disablePadding: false, label: "FECHA" },
  { id: "cant", numeric: true, disablePadding: false, label: "CANTIDAD" },
  { id: "prod", numeric: true, disablePadding: false, label: "PRODUCTOS" },
  { id: "total", numeric: true, disablePadding: false, label: "TOTAL" },
  { id: "estado", numeric: false, disablePadding: false, label: "ESTADO" },
  { id: "comentario", numeric: false, disablePadding: false, label: "REFERENCIA" },
  { id: "motorizado", numeric: false, disablePadding: false, label: "REPARTIDOR" },
];
   
function EnhancedTableHead(props) {

  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell align="center">OPCIONES</StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
          
        ))} 
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontSize:'12px !important',
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  section_buscar:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    float:'right',
    "@media(max-width:702px)":{
        justifyContent: 'center',
        float:'none',
            }
},
btn_search:{
  border: 'none',
backgroundColor: '#02AAB0',
height: '40px',
margin: '7px',
borderRadius: '12px',
color: 'white',
outline:'none',
"&:hover": {
  backgroundColor: "#00858a",
},
"&:active": {
 position: 'relative',
 top:'1px',
},
},
 typography: {
    padding: theme.spacing(2),
  },
}));
  

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  
  const {sales,setsales} = props
  
  var nuevo1 = sales

  // console.log(nuevo1)


  
  const [search , setsearch ] = useState('')


  const handleChange = (e) =>{
      setsearch(e.target.value)
      
  }

  if(search === ""){
    setsales(sales)
  }


  const handleclick = (e) =>{
    
    if (e.key === 'Enter' && !e.shiftKey) {
      if(search !== ''){
        const nuevo = sales.filter(nuevo => nuevo.fk_IdPedido_venta === search || nuevo.nro_documento === search )
        setsales(nuevo)
        setsearch('')
      }else{
        Axios
        .get(`${url}ecommerce/ventas`)
        .then(res=>{
          // console.log(res)
            setsales(res.data)
        })
       
      }
    }
   

      
  }


  const sales1= sales.map(item =>{
    return createData(item.fk_IdPedido_venta,item.nro_documento,item.cliente,item.direccion_cliente,item.distrito_cliente,item.telefono_cliente,item.fecha_venta,item.cantidad_compra,item.pago_venta,item.estado_entrega,item.comentario_ped,item.repartidor,item.IdVenta)
  })


  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const emptyRows =
  rowsPerPage - Math.min(rowsPerPage, sales1.length - page * rowsPerPage);

 

  const handlereset = () =>{
    Axios
    .get(`${url}ecommerce/ventas`)
    .then(res=>{
       console.log(res)
        setsales(res.data)
    })
  }

  const dia = new Date()

    const mes = dia.getMonth()
    const mes1 = mes +1 
    const año = dia.getFullYear()
    
    const fecha = `Ventas ${mes1}/${año}`

  const printDocument =() => {  
    const input = document.getElementById('pdfdiv');  
    html2canvas(input)  
      .then((canvas) => {  
        var imgWidth = 295;  
        var pageHeight = 290;  
        var imgHeight = canvas.height * imgWidth / canvas.width;  
        var heightLeft = imgHeight;  
        const imgData = canvas.toDataURL('image/png');  
        const pdf = new jsPDF('l', 'mm', 'a4',)  
        var position = 0;  
        var heightLeft = imgHeight;  
        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);  
        pdf.save(fecha);  
      });  
  }  




  
  return (
    <div className={classes.root}>

      <VentaCompletada sales={sales} printDocument={printDocument} status='complete'></VentaCompletada>

         <div className={classes.section_buscar}>
            <Typography style={{margin:10}}>Buscar:</Typography>
           <TextField 
           placeholder="Ingrese" 
           variant="outlined" 
           style={{height:'35px !important'}} 
           value={search} 
           name="search"
           onChange={handleChange}
           onKeyPress={handleclick}
           ></TextField>
           <button className={classes.btn_search}
           onClick={handleclick}
           ><SearchIcon/></button>
             <button className={classes.btn_search} onClick={handlereset} ><LoopIcon/></button>
                </div>    
        


      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            id='pdfdiv'
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}

              onRequestSort={handleRequestSort}
              rowCount={sales1.length}
            />
            <TableBody>
              {stableSort(sales1, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      // hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      <StyledTableCell width="100px" align="center">
                        <ButtonUpdateSale id={row.id} status={row.estado} idmoto={row.IdVenta}></ButtonUpdateSale>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        align="center"
                        scope="row"
                        padding="none"
                      >
                      <PopoverInfoSale id={row.id}></PopoverInfoSale>
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.dni_ruc}</StyledTableCell>
                      <StyledTableCell align="center">{row.cliente}</StyledTableCell>
                      <StyledTableCell align="center">{row.direccion}</StyledTableCell>
                      <StyledTableCell align="center">{row.distrito}</StyledTableCell>
                      <StyledTableCell align="center">{row.celular}</StyledTableCell>
                      <StyledTableCell align="center">{row.fecha}</StyledTableCell>
                      <StyledTableCell align="center">{row.cant}</StyledTableCell>
                      <StyledTableCell align="center"><ProductsSale id={row.id}></ProductsSale></StyledTableCell>
                      <StyledTableCell align="center">{row.total}</StyledTableCell>
                      <StyledTableCell align="center">
                          <StatusSales status={row.estado}></StatusSales>
                      </StyledTableCell>
                     
                      <StyledTableCell  align="center">
                       
                        <PopoverTabel row={row.comentario}></PopoverTabel>
                          
                      </StyledTableCell>
                      <StyledTableCell  align="center">
                       
                       {row.repartidor}  <br></br>
                       <ButtonUpdateDelivery id={row.id} status={row.estado} idmoto={row.IdVenta}></ButtonUpdateDelivery>
                         
                     </StyledTableCell>
                    </TableRow>
                  );
                })}
                
              {emptyRows > 0 && (
                <TableRow >
                  <StyledTableCell colSpan={14} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por Página"
          component="div"
          count={sales1.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      
    </div>
  );
}
