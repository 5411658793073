import { Box, Typography } from '@material-ui/core'
import React from 'react'

const ProductHeader = () => {
    return (
        <Box
        display="flex"
        flexWrap="nowrap"
        p={1}
        mt={5}
        bgcolor="background.paper"
        css={{ width:'100%' ,  
        justifyContent:'space-around',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px' }}
        >
        <Box p={1} mx={1} style={{textAlign:'center'}} width='50px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>ID</Typography>
        </Box>
        <Box p={1} mx={4} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Nombre</Typography>
        </Box>
        <Box p={1} mx={5} style={{textAlign:'center'}} width='250px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Descripción</Typography>
        </Box>
        <Box p={1} mx={3} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Imagen</Typography>
        </Box>
        <Box p={1} mx={4} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Precio</Typography>
        </Box>
        <Box p={1} mx={4} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Stock</Typography>
        </Box>
        <Box p={1} mx={5} style={{textAlign:'center'}} width='150px'>
        <Typography variant="body1" style={{fontWeight:'700'}}>Acciones</Typography>
        </Box>
      </Box>
    )
}

export default ProductHeader
