import { CssBaseline, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import React from 'react'
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import StoreIcon from '@material-ui/icons/Store';
import CloseIcon from '@material-ui/icons/Close';
import logo from '../assets/logo2.png'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HelpIcon from '@material-ui/icons/Help';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Link } from 'react-router-dom';
import "../assets/scroll.css";


const Menu = ( props) => {
    
    const {setOpen} = props
    const handleClose = () =>{
        setOpen(false)
    }
    return (
        <>
        <CssBaseline></CssBaseline>
        <List component="nav" aria-label="main mailbox folders" color="secondary" style={{backgroundColor:'#02AAB0',height:'auto',minHeight:'100%'}}>
        <ListItem button 
        onClick={handleClose} 
        >
          <ListItemIcon style={{margin:'5px'}} >
          <CloseIcon style={{display:'flex', justifyContent:'flex-end'}} color="secondary" ></CloseIcon>
          </ListItemIcon>
        </ListItem>
        <ListItem >
          <ListItemIcon style={{margin:'5px'}}>
          <Typography variant="h6" >
            <img src={logo} style={{width:'250px'}} ></img>
            </Typography>
          </ListItemIcon>
          
        </ListItem>

        <Divider style={{backgroundColor:'white'}}></Divider>

        <Link to="/" style={{outline:'none',textDecoration:'none',color:'inherit'}}>
        <ListItem button >
          <ListItemIcon style={{margin:'5px',padding:'10px'}}>
          <DashboardIcon  style={{marginRight:'3px'}} color="secondary"></DashboardIcon>
          </ListItemIcon>
          <Typography variant="h6" color="secondary">DARSHBOARD</Typography>
        </ListItem>
        </Link> 

        <Link to="/addadmin" style={{outline:'none',textDecoration:'none',color:'inherit'}}>
        <ListItem button >
          <ListItemIcon style={{margin:'5px',padding:'10px'}}>
          <SupervisorAccountIcon style={{marginRight:'3px'}} color="secondary"></SupervisorAccountIcon>
          </ListItemIcon>
          <Typography variant="h6" color="secondary">ADMINISTRADOR</Typography>
        </ListItem>
        </Link>


       {/* <Link to="/profile" style={{outline:'none',textDecoration:'none',color:'inherit'}}>
        <ListItem button >
          <ListItemIcon style={{margin:'5px',padding:'10px'}}>
          <PersonIcon style={{marginRight:'3px'}} color="secondary"></PersonIcon>
          </ListItemIcon>
          <Typography variant="h6" color="secondary">PERFIL DE USUARIO</Typography>
        </ListItem>
        </Link> 
 
         <Link to="/store" style={{outline:'none',textDecoration:'none',color:'inherit'}}>
        <ListItem button >
          <ListItemIcon style={{margin:'5px',padding:'10px'}}>
          <StoreIcon style={{marginRight:'3px'}} color="secondary"></StoreIcon>
          </ListItemIcon>
          <Typography variant="h6" color="secondary">TIENDA</Typography>
        </ListItem>
        </Link>  */}

        <Link to="/category" style={{outline:'none',textDecoration:'none',color:'inherit'}}>
        <ListItem button >
          <ListItemIcon style={{margin:'5px',padding:'10px'}}>
          <AccountTreeIcon style={{marginRight:'3px'}} color="secondary"></AccountTreeIcon>
          </ListItemIcon>
          <Typography variant="h6" color="secondary">CATEGORIAS</Typography>
        </ListItem>
        </Link>

        <Link to="/product" style={{outline:'none',textDecoration:'none',color:'inherit'}}>
        <ListItem button >
          <ListItemIcon style={{margin:'5px',padding:'10px'}}>
          <LocalOfferIcon style={{marginRight:'3px'}} color="secondary"></LocalOfferIcon>
          </ListItemIcon>
          <Typography variant="h6" color="secondary">PRODUCTO Y OFERTAS</Typography>
        </ListItem>
        </Link> 

        <Link to="/ventas" style={{outline:'none',textDecoration:'none',color:'inherit'}}>
        <ListItem button >
          <ListItemIcon style={{margin:'5px',padding:'10px'}}>
          <LocalMallIcon style={{marginRight:'3px'}} color="secondary"></LocalMallIcon>
          </ListItemIcon>
          <Typography variant="h6" color="secondary">VENTAS</Typography>
        </ListItem>
        </Link>


        {/* <Link to="/car" style={{outline:'none',textDecoration:'none',color:'inherit'}}>
        <ListItem button >
          <ListItemIcon style={{margin:'5px',padding:'10px'}}>
          <ShoppingCartIcon style={{marginRight:'3px'}} color="secondary"></ShoppingCartIcon>
          </ListItemIcon>
          <Typography variant="h6" color="secondary">CARRITO</Typography>
        </ListItem>
        </Link> */}

        {/* <Link to="/support" style={{outline:'none',textDecoration:'none',color:'inherit'}}>
        <ListItem button >
          <ListItemIcon style={{margin:'5px',padding:'10px'}}>
          <HelpIcon style={{marginRight:'3px'}} color="secondary"></HelpIcon>
          </ListItemIcon>
          <Typography variant="h6" color="secondary">SOPORTE Y AYUDA</Typography>
        </ListItem>
        </Link>  */}
        </List>
        </>
    )
}

export default Menu
