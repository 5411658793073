import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Popover, Typography } from '@material-ui/core'
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));

const ProductsDescription = ({descripcion}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


    return (
      <>
        <IconButton variant="contained" color="primary" onClick={handleClick}>
          <CommentIcon></CommentIcon>
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{width:'600px'}}
        >
             <Typography className={classes.typography}> {descripcion}</Typography>
       
        </Popover>
      </>
    );
}

export default ProductsDescription
