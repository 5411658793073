import { createMuiTheme } from "@material-ui/core/styles";
import { esES } from '@material-ui/core/locale';

const themeGlobal = createMuiTheme({
    palette:{
        primary:{
            main:'#02AAB0'
        },
        secondary:{
            main:'#ffffff'
        },
        background:{
            paper:'#ffffff',
            default:'#ffffff',
            select:'#02AAB0'
        },
        

    },
    mixins:{
        toolbar:{
            minHeight:60,
            
        }
    },
    shape: {
        borderRadius: 15,
      }, 
},esES)

export default themeGlobal