import { Button, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react';
import { RiFileExcel2Fill,RiFileCopyFill } from 'react-icons/ri';
import { FaFilePdf } from 'react-icons/fa';
import ReactExport from "react-export-excel";
import {CopyToClipboard} from 'react-copy-to-clipboard';
const useStyles=makeStyles((theme)=>({
    content:{
        padding:theme.spacing(3),
    },
    section_btn:{
        float:'left',
        display: 'flex',
        justifyContent: 'center',
        height: '56px',
        marginBottom:'35px',
        "@media(max-width:600px)":{
        float:'none',
            }
    },
    section_buscar:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        float:'right',
        "@media(max-width:702px)":{
            justifyContent: 'center',
            float:'none',
                }
    },
    btn:{
       margin:'10px',
       color:'#1b32b3',
    }

}));
const VentaCompletada = ( props ) => {
    const classes=useStyles();
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const {sales,printDocument,status,printDocument1 } =props
    
  
    const [copied,setcopie] = useState({
        copied: false,
    })

   
    const dia = new Date()

    const mes = dia.getMonth() 
    const mes1 = mes +1 
    const año = dia.getFullYear()
    
    const fecha = `Ventas ${mes1}/${año}`

    const fecha1 = `Ventas Fallidas ${mes1}/${año}`

    return (
        <main>
        {/* <div className={classes.content}> */}
            {/* SECTOR BOTONES - BUSCADOR */}
         <div className={classes.section_btn}>
            <CopyToClipboard text={JSON.stringify(sales)} onCopy={() => setcopie({copied: true})}>
            <Button className={classes.btn} ><RiFileCopyFill/>Copy</Button> 
            </CopyToClipboard>
            {
                status === 'complete' ?
                <>
                <ExcelFile element={<Button className={classes.btn} ><RiFileExcel2Fill/>Excel</Button>} filename={fecha}>
                <ExcelSheet  data={sales} name="Ventas">
                    <ExcelColumn label="Numero de documento " value="nro_documento"/>
                    <ExcelColumn label="Cliente" value="cliente"/>
                    <ExcelColumn label="Direccion" value="direccion_cliente"/>
                    <ExcelColumn label="Distrito" value="distrito_cliente"/>
                    <ExcelColumn label="Celular" value="telefono_cliente"/>
                    <ExcelColumn label="Fecha" value="fecha_venta"/>
                    <ExcelColumn label="Cantidad" value="cantidad_compra"/>
                    <ExcelColumn label="Total" value="pago_venta"/>
                    <ExcelColumn label="Referencia" value="comentario_ped"/>
                </ExcelSheet>
                </ExcelFile>
                </>
                :
                <>
                <ExcelFile element={<Button className={classes.btn} ><RiFileExcel2Fill/>Excel</Button>} filename={fecha1}>
                <ExcelSheet  data={sales} name="Ventas">
                    <ExcelColumn label="Numero de documento " value="nro_documento"/>
                    <ExcelColumn label="Cliente" value="nombres"/>
                    <ExcelColumn label="Celular" value="telf_ped"/>
                    <ExcelColumn label="Email" value="email_cliente"/>
                    <ExcelColumn label="Fecha" value="fecha_pedido"/>
                </ExcelSheet>
                </ExcelFile>
                </>
            }             
            {
                status === 'complete' ?
                    <Button className={classes.btn} onClick={printDocument}><FaFilePdf/>PDF</Button>
                    :
                    <Button className={classes.btn} onClick={printDocument1}><FaFilePdf/>PDF</Button>
            }
            
                </div>
       </main>
    )
}

export default VentaCompletada
