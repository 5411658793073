import React from 'react'
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import foto from '../assets/fotoproduct.png'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Box, IconButton, Typography } from '@material-ui/core'
import ButtonUpdateOffer from '../components/ButtonUpdateOffer'
import { useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import {url} from '../data/url'
const OfferCard = (props) => {
  const history = useHistory()

    const [offer, setoffer] = useState({ 
    IdProducto:props.IdOffer,
    descuento:props.descuento,
    fecha_inicio:props.fecha_inicio,
    fecha_fin:props.fecha_fin
    })


    console.log(offer)

    const handleactualizar = () =>{
      Axios
      .put(`${url}admin/offers`,offer)
      .then(res=>{
        console.log(res)
        setTimeout(() => {
            
          history.push('/offer')
        }, 1500);
      })

    }


    return (
        <Box
        display="flex"
        flexWrap="nowrap"
        p={1}
        mt={5}
        bgcolor="background.paper"
        css={{ width:'100%' ,  
        justifyContent:'space-around',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px' }}
        >
        <Box p={1} mx={3} display='flex' width='100px' style={{justifyContent:'center',alignItems:'center',textAlign:'center'}}>
        <Typography variant="body2" style={{fontWeight:'700'}}>{props.IdOffer}</Typography>
        </Box>
        <Box p={1}  mx={5} display='flex' width='150px' style={{justifyContent:'center',alignItems:'center',textAlign:'center'}}>
        <Typography variant="body2" style={{fontWeight:'700'}}>{props.nombre_offer}</Typography>
        </Box>
        <Box p={1}  mx={5}  display='flex' width='150px' style={{justifyContent:'center',alignItems:'center',textAlign:'center'}}>
        <Typography variant="body2" style={{fontWeight:'700'}}><img src={props.avatar_offer} alt="fotoproduct" style={{width:'70%'}}></img></Typography>
        </Box>
        <Box p={1}  mx={5} display='flex' width='150px' style={{justifyContent:'center',alignItems:'center',textAlign:'center'}}>
        <Typography variant="body2" style={{fontWeight:'700'}}>{props.descuento}%</Typography>
        </Box>
        <Box p={1} mx={5} width='150px' display='flex' style={{justifyContent:'center',alignItems:'center',textAlign:'center'}}>
        <Typography variant="body2" style={{fontWeight:'700'}}>{props.fecha_inicio}</Typography>
        </Box>
        <Box p={1} mx={5} width='150px' display='flex' style={{justifyContent:'center',alignItems:'center',textAlign:'center'}}>
        <Typography variant="body2" style={{fontWeight:'700'}}>{props.fecha_fin}</Typography>
        </Box>
        <Box p={1}  mx={5} display='flex' width='150px' style={{justifyContent:'center',alignItems:'center',textAlign:'center'}}>
        {/* <IconButton color="primary">
        <LocalOfferIcon color="primary">
        </LocalOfferIcon>
        </IconButton> */}
        <ButtonUpdateOffer setoffer={setoffer} offer={offer} handleactualizar={handleactualizar}></ButtonUpdateOffer>
        
        </Box>
      </Box>
    )
}

export default OfferCard
