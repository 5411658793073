import { Typography } from '@material-ui/core'
import React from 'react'

const StatusSales = (props) => {

    const {status} = props 
    return (
        <>
        {
            status === 'entregado' ?
            <Typography style={{backgroundColor:'#2e4ac7',color:'white',fontSize:'11px'}}>{status}</Typography>
            :
            <Typography style={{backgroundColor:'#e30e23',color:'white',fontSize:'11px'}}>{status}</Typography>
        }
        </>
        
    )
}

export default StatusSales


