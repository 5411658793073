import React from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Hidden,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { RiLockPasswordLine, RiUserLine } from "react-icons/ri";
import logo from "../assets/logo2.png";
import { useState } from "react";
import { AdminContext } from "../context/AdminContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import {url} from '../data/url'
import { Alert, AlertTitle } from "@material-ui/lab";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: "100vh",
    margin: "150px",
  },
  grid_item_left: {
    backgroundColor: "#02AAB0",
    width: "50%",
    borderBottomLeftRadius: "40px",
    borderTopLeftRadius: "40px",
  },
  box_logo: {
    display: "flex",
    justifyContent: "center",
    margin: "50px",
  },
  box_msg: {
    display: "grid",
    justifyContent: "center",
    margin: "50px",
    color: "white",
    textAlign: "center",
  },
  btn_login: {
    backgroundColor: "#02AAB0",
    color: "white",
    width: "215px",
    height: "50px",
    marginTop: "25px",
    marginBottom: "25px",
    "&:hover": {
      backgroundColor: "#00858a",
    },
    "&:active": {
      top: "2px",
      position: "relative",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }

}));

const CssTextField = withStyles({
  root: {
    color: "black",
    marginTop: "20px",
    marginBottom: "20px",
    "& label.Mui-focused": {
      color: "black",
      fontStyle: "bold",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#02AAB0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "#02AAB0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#02AAB0",
      },
    },
    
  },

})(TextField);

const Login = () => {
  const classes = useStyles();

  const [state, setstate] = useState({
    usuario: "",
    contrasena: "",
  });

  const handleChange = ({ target }) => {
    setstate({
      ...state,
      [target.name]: target.value,
    });
  };


  const {value,dispatch} = useContext(AdminContext)

  const history = useHistory()

  const [message,setMessage] = useState({
    type:'',
    message:'',
    message1:'',
    status:false
  })

  const timer = useRef()
  const handleAuth = () =>{


    fetch(`${url}admin/login`,{
      method: 'POST',
      body:JSON.stringify(state)
    })
    .then(response => response.json())
    .then( response => {
      console.log(response)
      setMessage({
        ...message,
        type:'success',
        message:'Iniciaste Sesion',
        message1:response.message,
        status:true
      })
      if(response.success === 1){
        Axios
          .get(`${url}admin/user-info`,
          {
            headers: {
              'Authorization' :'Bearer '+ response.token
            }
          })
          .then(res =>{
            setOpen(true);
            timer.current = setTimeout(()=>{
              
              setMessage({
                ...message,
                status:false
              })
              
              history.replace("/")
              dispatch({
                type:'login'
              })
            },3000)
            
          })
      }
      else{
        setMessage({
          ...message,
          type:'warning',
          message:'Advertencia',
          message1:response.message,
          status:true
        })
        timer.current = setTimeout(()=>{
              
          setMessage({
            ...message,
            status:false
          })
        },3000)
      }
    })
    .catch(err =>{
      console.log(err);
    })
      
  }
  
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
 
  return (
    <main className={classes.main}>
      <Grid container style={{boxShadow: '3px 4px 21px -8px rgb(112, 112, 112)',borderRadius: '40px'}}>
       
        <Grid item xs={6} className={classes.grid_item_left}>
          <Box className={classes.box_logo}>
            <img src={logo} alt="logo"></img>
          </Box>
          <Box className={classes.box_msg}>
            <Typography style={{ fontSize: "25px" }}>
              Bienvenido Administrador
            </Typography>
            <br></br>
            <Typography style={{ fontSize: "18px", marginTop: "60px" }}>
              Inicie sesión para acceder a la plataforma de mantenimiento de la tienda virtual
            </Typography>
          </Box>
        </Grid>
       
        <Grid item xs={6} style={{ width: "50%", padding: "30px" }}>
          <Typography style={{ fontSize: "25px", textAlign: "center" }}>
            INICIO DE SESIÓN
          </Typography>
          <form noValidate>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                width: "70%",
                margin: "auto",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={state.usuario}
                onChange={handleChange}
                label="Usuario"
                name="usuario"
                placeholder="Ingrese su usuario"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiUserLine />
                    </InputAdornment>
                  ),
                }}
                autoComplete="off"
              />
              <CssTextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                name="contrasena"
                label="Contraseña"
                value={state.contrasena}
                onChange={handleChange}
                type="password"
                placeholder="Ingrese su contraseña"
                autoComplete="nope"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiLockPasswordLine />
                    </InputAdornment>
                  ),
                }}
              
              />

              <Button className={classes.btn_login} onClick={handleAuth}>Iniciar Sesion</Button>
              <br></br>
              {
                message.status &&
                ( 
                <>
                <Alert severity={message.type}>
                <AlertTitle>{message.message}</AlertTitle>
                {message.message1}
              </Alert>
              <Backdrop className={classes.backdrop} 
              open={open} 
              onClick={handleClose}>
              <CircularProgress color="inherit" />
            </Backdrop>
            </>)
              }
            </Grid>
          </form>
        </Grid>
      </Grid>
    </main>
  );
};
export default Login;
