import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputBase, NativeSelect, TextField, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import FormProduct from '../components/FormProduct';
import { useHistory } from 'react-router-dom';
import CachedIcon from '@material-ui/icons/Cached';
import CategoryComponent from '../components/CategoryComponent'
import UploadCategory from '../views/UploadCategory'
const useStyles = makeStyles((theme)=>({
  content:{
      flexGrow:1,
      paddingBottom:theme.spacing(4)
    },
    formControl:{
      width:'250px',
      color:'#02AAB0',
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#02AAB0",
        borderWidth:'3px'
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#02AAB0",
        borderWidth:'3px'
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#02AAB0",
        borderWidth:'3px'
      },
  },
  inputfile:{
      padding:'30px',
      // backgroundColor:'rgba(47, 176, 193, 0.51);',
      color:'black',
      borderRadius:'10px',
      cursor:'pointer',
      border:'none',
      outline:'none'

  },
  btn_category: {
    backgroundColor: "#02AAB0",
    color: "white",
    fontSize: "18px",
    borderRadius: "20px",
    width: "200px",
    "&:hover": {
      backgroundColor: "#00858a",
    },
    "&:active":{
      position: 'relative',
      top:'2px',
    }
  },
  
}))

const ButtonUpdateCategory = (props) => {
    const history = useHistory()
    const classes = useStyles()

    
      const [open, setOpen] = React.useState(false);

       const handleClickOpen = () => {
         setOpen(true);
       };
    
       const handleClose = () => {
         setOpen(false);
       };


    return (
      <>
        <Button className={classes.btn_icons} onClick={handleClickOpen}>
          <CachedIcon color="primary" />
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogContent>
            <UploadCategory id={props.id}></UploadCategory>
          </DialogContent>
        </Dialog>
      </>
    );
}

export default ButtonUpdateCategory;
