import { Drawer } from '@material-ui/core'
import React from 'react'
import Menu from '../components/Menu'
import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>({
    drawer: {
        width: 100,
        flexShrink: 0,
        backgroundColor:theme.palette.background.paper,
      },
      drawerPaper: {
        width: drawerWidth,
      }
}))
const Drawe = (props) => {
    const classes = useStyles()
    const {open , setOpen} = props
    const handleOpen = () =>{
        setOpen(false)
    }
    return (
        <Drawer
        className={classes.drawer}
        variant="temporaly"
        anchor="left"
        // classes={{
        //   paper: classes.drawerPaper,
        // }}
        open={open}
        onClick={handleOpen}
        >
          <Menu setOpen={setOpen} ></Menu>
        </Drawer>
    )
}

export default Drawe
