import React, { useReducer } from 'react';
import {BrowserRouter as Router, Switch , Route} from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from './themeGlobal'
import AdminRoutes from './routes/AdminRoutes'
import Login from './views/Login'
import { AdminContext } from './context/AdminContext';
import AdminReducer from './reducers/AdminReducer'
import { useEffect } from 'react';
import PrivateRoutes from './routes/PrivateRoute';
import PublicRoutes from './routes/PublicRoutes';

function App() {

  const init = () =>{
    return JSON.parse(sessionStorage.getItem('user')) || 
   {
     isLogged: false 
   }   
 }

  const [value,dispatch] = useReducer(AdminReducer,{},init)

  useEffect(() => {
    sessionStorage.setItem('user',JSON.stringify(value))
  }, [value])


  
  return (
    <AdminContext.Provider value={{value,dispatch}}>
    <ThemeProvider theme={theme}>
      <CssBaseline>
      
      <Router>
        <div>
          <Switch>
            <PublicRoutes
            isAuth={value.isLogged}
            exact
            path="/login" 
            component={Login}
            ></PublicRoutes>

            <PrivateRoutes
            isAuth={value.isLogged}
            path="/" 
            component={AdminRoutes}
            ></PrivateRoutes>
          </Switch>
        </div>
      </Router>
     
      </CssBaseline>
    </ThemeProvider>
    </AdminContext.Provider>
  );
}

export default App;
