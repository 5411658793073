import Axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import {url} from '../data/url'
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Popover, Typography } from '@material-ui/core'
import NoteIcon from '@material-ui/icons/Note';
import LocalMallIcon from '@material-ui/icons/LocalMall';

const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));

const ProductsSale = ({id}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [products, setProducts] = useState([])


  
 
    useEffect(() => {
       Axios
       .post(`${url}admin/ventas_detalle`,{
           id: id
       })
       .then(res =>{
        setProducts(res.data)
       })
    }, [])

 

    return (
      <>
        <IconButton variant="contained" color="primary" onClick={handleClick}>
            {/* cambiar de icono */}
          <LocalMallIcon></LocalMallIcon>
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          
          {
              products.map(item =>{
                  return <Typography className={classes.typography}>{item.nombre} x {item.cantidad} </Typography>
              })
          }
        </Popover>
      </>
    );
}

export default ProductsSale
