import React from 'react'

const Store = () => {
    return (
        <div>
            store
        </div>
    )
}

export default Store
