import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useEffect } from "react";
import Axios from "axios";
import {url} from "../data/url"
import firebase from 'firebase'
import ImageIcon from '@material-ui/icons/Image';
const CssTextField = withStyles({
  root: {
    color: "white",
    width: "500px",
    backgroundColor: "#F2F2F2",

    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#02AAB0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#02AAB0",
        border: "2px solid #02AAB0",
        borderRadius: "7px !important",
      },
      "&:hover fieldset": {
        borderColor: "#02AAB0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#02AAB0",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
    btn_category: {
        backgroundColor: "#02AAB0",
        color: "white",
        fontSize: "18px",
        borderRadius: "20px",
        width: "200px",
        "&:hover": {
          backgroundColor: "#00858a",
        },
      },


})); 

const CategoryComponent= ({setAddcategory,addcaegory}) => {
  const classes = useStyles();
  const [state, setstate] = useState({
    nombrecategoria: '',
  });

  console.log(state.nombrecategoria)

  const handleChange = ({ target }) => {
    setstate({
      ...state,
      [target.name]: target.value,
    });

  };
  const [category, setCategory] = React.useState(true);
  const handleChangeSwitch = (event) => {
    setCategory(event.target.checked);

  };


  const [image, setimage] = useState(null)

  const handleImage= (e) =>{
    setimage(e.target.files[0])
  }

  const [imagefirebase,setimagefirebase] = useState(null)

  const handleupload = () =>{
    const storageRef = firebase.storage().ref(`/portadas/${image.name}`).put(image)
    storageRef.on(
      "state_changed",
      snapshot => {},
      error =>{
        console.log(error)
      },
       ()=>{
        firebase.storage()
        .ref("portadas")
        .child(image.name)
        .getDownloadURL()
        .then(url =>{
          setimagefirebase(url)
          setAddcategory({
            ...addcaegory,
            Portada:url
          })
        })
      }
    )
  }

  useEffect(() => {
    setAddcategory({
      ...addcaegory,
      Nombre:state.nombrecategoria,
      Condicion: category === true ? "1" : "0" 
    })
  }, [state,category,image])


  return (
    <main style={{margin: "10px 5px 15px 150px" }}>
      <form>
        <CssTextField
          variant="outlined"
          margin="normal"
          value={state.nombrecategoria}
          name="nombrecategoria"
          type="text"
          onChange={handleChange}
          label="Nombre de la categoria"
          placeholder="Ingrese el nombre para la categoria"
        />

        <Typography
          style={{ fontSize: "18px", marginTop: "20px", marginBottom: "20px" }}
        >
          Subir Portada
        </Typography>

        <Grid
          item
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginBottom: "45px",
          }}
          spacing={2}
        >
          <input 
          type="file"
          accept="image/jpeg"
          id="real-file"
          hidden="hidden"
          onChange={handleImage}
          ></input>
          <Button
            type="button"
            variant="contained"
            color="primary"
            id="custom-button"
            className={classes.inputfile}
            style={{ display: "flex", alignContent: "center" }}
            onClick={() => {
              const realFileBtn = document.getElementById("real-file");
              realFileBtn.click();
            }}
          >
           <ImageIcon
          color="secondary"
          style={{ marginRight: "10px" }}
        ></ImageIcon>
            <Typography color="secondary" style={{ fontWeight: "700" }}>
              Escoger Imagen
            </Typography>
          </Button>
          <Typography
            id="custom-text"
            style={{ padding: "20px", marginLeft: "10px" }}
          >
            Ningun archivo seleccionado
          </Typography>

          {
            image !== null &&
            <Button onClick={handleupload} 
            type="button"
            className={classes.inputfile} 
            color="primary" 
            variant="contained">
              <CloudUploadIcon
              color="secondary"
              style={{ marginRight: "10px" }}
            ></CloudUploadIcon>
              <Typography color="secondary" style={{ fontWeight: "700" }}>
              Subir Imagen
          </Typography></Button>
          }
         
        
        </Grid>
       {
          imagefirebase !== null &&
          <div style={{display:'flex',justifyContent:'center'}}>
          <img src={imagefirebase} height="200" alt="Image preview..." id="imgra"></img>   
          </div>
        }
        
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={6}>
            <Typography >Habilitar/Ocultar Categoria</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <FormGroup>
              <FormControlLabel
                style={{ margin: "auto" }}
                control={
                  <Switch
                    checked={category}
                    onChange={handleChangeSwitch}
                    aria-label="category switch"
                    color="primary"
                  />
                }
                //   label={auth ? 'Logout' : 'Login'}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </form>
    </main>
  );
};

export default CategoryComponent;
