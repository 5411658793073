import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useCategory } from "../hooks/useCategory";
import firebase from "firebase";
import ImageIcon from "@material-ui/icons/Image";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    color: "#02AAB0",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#02AAB0",
      borderWidth: "3px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#02AAB0",
      borderWidth: "3px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#02AAB0",
      borderWidth: "3px",
    },
  },
  inputfile: {
    padding: "30px",
    // backgroundColor:'rgba(47, 176, 193, 0.51);',
    color: "black",
    borderRadius: "10px",
    cursor: "pointer",
    border: "none",
    outline: "none",
  },
}));
const FormProduct = ({ addproduct, setAddproduct, handleChange, add }) => {
  const classes = useStyles();
  const { categories } = useCategory();

  const [idcat, setidcat] = useState("");

  const handleChange1 = (e) => {
    setidcat(e.target.value);
  };

  useEffect(() => {
    setAddproduct({
      ...addproduct,
      IdCategoria: idcat,
    });
  }, [idcat]);

  const [image, setimage] = useState(null);

  const handleImage = (e) => {
    setimage(e.target.files[0]);
  };

  // useEffect(()=>{

  //   const storageRef = firebase.storage().ref(`/portadas/${image.name}`).put(image)
  //   storageRef.on(
  //     "state_changed",
  //     snapshot => {},
  //     error =>{
  //       console.log(error)
  //     },
  //      ()=>{
  //       firebase.storage()
  //       .ref("portadas")
  //       .child(image.name)
  //       .getDownloadURL()
  //       .then(url =>{
  //         setimagefirebase(url)
  //         setAddproduct({
  //           ...addproduct,
  //           avatar_producto:url
  //         })
  //       })
  //     }
  //   )

  // },[image])

  const [imagefirebase, setimagefirebase] = useState(null);

  const handleupload = () => {
    const storageRef = firebase
      .storage()
      .ref(`/portadas/${image.name}`)
      .put(image);
    storageRef.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        firebase
          .storage()
          .ref("portadas")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            setimagefirebase(url);
            setAddproduct({
              ...addproduct,
              avatar_producto: url,
            });
          });
      }
    );
  };

  return (
    <div
      style={{
        // display:'flex',
        // justifyContent:'center',
        paddingTop: "10px",
        width: "80%",
        // height:'500px',
        margin: "auto",
      }}
    >
      <form style={{ width: "100%" }}>
        <br></br>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5" style={{ flexGrow: 1 }}>
            Datos de Producto
          </Typography>
        </div>

        <br></br>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <TextField
              id="outlined-basic"
              label="Nombre del producto"
              variant="outlined"
              value={addproduct.nombre_producto}
              name="nombre_producto"
              style={{ width: "100%" }}
              className={classes.formControl}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={6}>
            {add && (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Escoger Categoria
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={handleChange1}
                  label="Categorias"
                  style={{ backgroundColor: "white" }}
                  value={addproduct.nombre_categoria}
                >
                  <MenuItem value={0}>
                    <em>Escoger una Categoria</em>
                  </MenuItem>
                  {categories.map((item) => {
                    return (
                      <MenuItem value={item.IdCategoria}>
                        {item.Nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
        <br></br>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <TextField
              id="outlined-basic"
              label="Ingrese Precio"
              value={addproduct.precio_producto}
              name="precio_producto"
              variant="outlined"
              style={{ width: "100%" }}
              className={classes.formControl}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              id="outlined-basic"
              label="Ingrese Stock"
              variant="outlined"
              value={addproduct.stock_producto}
              name="stock_producto"
              style={{ width: "100%" }}
              className={classes.formControl}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <br></br>
        <Grid container>
          <Grid item sm={12}>
            <TextField
              id="outlined-basic"
              label="Ingrese la Descripción"
              variant="outlined"
              style={{ width: "100%" }}
              value={addproduct.descripcion_producto}
              name="descripcion_producto"
              className={classes.formControl}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <br></br>
        <Typography variant="h6">Escoger la portada</Typography>

        <Grid
          item
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          spacing={2}
        >
          <input
            type="file"
            id="real-file"
            hidden="hidden"
            onChange={handleImage}
          />
          <Button
            type="button"
            variant="contained"
            color="primary"
            id="custom-button"
            className={classes.inputfile}
            style={{ display: "flex", alignContent: "center" }}
            onClick={() => {
              const realFileBtn = document.getElementById("real-file");
              realFileBtn.click();
            }}
          >
            <ImageIcon
              color="secondary"
              style={{ marginRight: "10px" }}
            ></ImageIcon>

            <Typography color="secondary" style={{ fontWeight: "700" }}>
              Escoge una imagen
            </Typography>
          </Button>
          <Typography
            id="custom-text"
            style={{ padding: "30px", marginLeft: "10px" }}
          >
            Ningun archivo seleccionado
          </Typography>
          {image !== null && (
            <Button
              onClick={handleupload}
              type="button"
              className={classes.inputfile}
              color="primary"
              variant="contained"
            >
              <CloudUploadIcon
                color="secondary"
                style={{ marginRight: "10px" }}
              ></CloudUploadIcon>
              <Typography color="secondary" style={{ fontWeight: "700" }}>
                Subir Imagen
              </Typography>
            </Button>
          )}
        </Grid>
        {imagefirebase !== null && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={imagefirebase}
              height="200"
              alt="Image preview..."
              id="imgra"
            ></img>
          </div>
        )}
      </form>
    </div>
  );
};

export default FormProduct;
