import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { RiLockPasswordLine, RiUserLine } from "react-icons/ri";
import logo from "../assets/logo2.png";
import { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import Axios from "axios";
import {url} from '../data/url'
import { useRef } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: "100vh",
    margin: "150px",
    
  },
  grid_item_left: {
    backgroundColor: "#02AAB0",
    width: "50%",
    borderBottomLeftRadius: "40px",
    borderTopLeftRadius: "40px",
  },
  box_logo: {
    display: "flex",
    justifyContent: "center",
    margin: "50px",
  },
  box_msg: {
    display: "grid",
    justifyContent: "center",
    margin: "50px",
    color: "white",
    textAlign: "center",
  },
  btn_login: {
    backgroundColor: "#02AAB0",
    color: "white",
    width: "215px",
    height: "50px",
    marginTop: "25px",
    marginBottom: "25px",
    "&:hover": {
      backgroundColor: "#00858a",
    },
    "&:active": {
      top: "2px",
      position: "relative",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    color: "black",
    marginTop: "20px",
    marginBottom: "20px",
    "& label.Mui-focused": {
      color: "black",
      fontStyle: "bold",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#02AAB0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "#02AAB0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#02AAB0",
      },
    },
  },
})(TextField);

const AddAdmin = () => {
  const classes = useStyles();

  const [state, setstate] = useState({
    nombres:"",
    apellidos:"",
    user: "",
    contrasena: "",
    tipo:2  
  });

  const handleChange = ({ target }) => {
    setstate({
      ...state,
      [target.name]: target.value,
    });
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
      '& .MuiNativeSelect-select:focus':{
        backgroundColor:'white'
      }
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#F2F2F2",
      border: "1px solid #02AAB0",
      fontSize: 15,
      color: "#6e6e6e",
      padding: "10px 26px 10px 12px",
      "@media(max-width:600px)":{
        fontSize:12,
      }
  
    },

  }))(InputBase);
  
  const options = ["Boleta", "Factura"];
  const [select, setselect] = useState("Admin")

  const handleselect = (e) =>{

    if(e.target.value === "Admin"){
      setselect(
        e.target.value
        )
        setstate({
          ...state,
          tipo:2  
        })
    }else{
      setselect(
        e.target.value,
        )
        setstate({
          ...state,
          tipo:3
        })
    }
     
  }

  console.log(select)
  console.log(state)
  const timer = useRef()
  const [alert,setalert]=useState({
    type:'',
    message:'',
    status:false,
    message1:''
  })
  const handleRegister = () =>{
    // Axios
    // .post(`${url}admin/register`,state)
    // .then(res=>{
    //   console.log(res)
    // })
    
    fetch(`${url}admin/register`,{
      method :'POST',
      body:JSON.stringify(state)
    })
    .then(res => res.json())
    .then(res =>{
      
      if(res.success === 1){
        setalert({
          ...alert,
          type:'success',
          message:res.message,
          status:true,
          message1:'¡Exitoso!'
        })
        timer.current = setTimeout(()=>{
          setalert({
            ...alert,
          status:false
          })
        },3000)

      }else{
        setalert({
          ...alert,
          type:'warning',
          message:res.message,
          status:true,
          message1:'¡Advertencia!'
        })
        timer.current = setTimeout(()=>{
          setalert({
            ...alert,
          status:false
          })
        },3000)
      }
      
    })
  }

  return (
    <main className={classes.main}>
      <Grid container style={{boxShadow: '3px 4px 21px -8px rgb(112, 112, 112)',borderRadius: '40px'}}>
        <Grid item xs={6} className={classes.grid_item_left} >
          <Box className={classes.box_logo}>
            <img src={logo} alt="logo"></img>
          </Box>
          <Box className={classes.box_msg}>
            <Typography style={{ fontSize: "25px" }}>
              Bienvenido Administrador
            </Typography>
            <br></br>
            <Typography style={{ fontSize: "18px", marginTop: "60px" }}>
              En este apartado puedes registrar a más administradores.
            </Typography>
          </Box>
          <Box className={classes.box_msg}>
          <NativeSelect
          className={classes.comprobante_select}
          value={select}
          name="select"
          onChange={handleselect}
          input={<BootstrapInput />}
          required
        >
          
          <option value="Admin" >Administrador</option>
          <option value="Moto" >Motorizado</option>
        </NativeSelect>
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: "50%", padding: "30px" }}>

          {
            select === "Admin" ?
            <>
            <Typography style={{ fontSize: "25px", textAlign: "center" }}>
            REGISTRAR ADMINISTRADOR
          </Typography>
          <form noValidate>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                width: "70%",
                margin: "auto",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={state.nombres}
                onChange={handleChange}
                label="Nombre"
                name="nombres"
                placeholder="Ingrese su nombre"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiUserLine />
                    </InputAdornment>
                  ),
                }}
              />
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={state.apellidos}
                onChange={handleChange}
                label="Apellidos"
                name="apellidos"
                placeholder="Ingrese sus apellidos"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiUserLine />
                    </InputAdornment>
                  ),
                }}
              />
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={state.user}
                onChange={handleChange}
                label="Usuario"
                name="user"
                placeholder="Ingrese su usuario"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiUserLine />
                    </InputAdornment>
                  ),
                }}
              />
              <CssTextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                name="contrasena"
                label="Contraseña"
                value={state.contrasena}
                onChange={handleChange}
                type="password"
                placeholder="Ingrese su contraseña"
                autoComplete="current-password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiLockPasswordLine />
                    </InputAdornment>
                  ),
                }}
              />

              <Button className={classes.btn_login} onClick={handleRegister}>Registrar</Button>
            </Grid>
            {
              alert.status &&
              <Alert severity={alert.type}>
              <AlertTitle>{alert.message1}</AlertTitle>
              {alert.message} — <strong>Revise esto!</strong>
            </Alert>
            }
          </form>
          </>
          :
          <>
          <Typography style={{ fontSize: "25px", textAlign: "center" }}>
            REGISTRAR MOTORIZADO
          </Typography>
          <form noValidate>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                width: "70%",
                margin: "auto",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={state.nombres}
                onChange={handleChange}
                label="Nombre"
                name="nombres"
                placeholder="Ingrese su nombre"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiUserLine />
                    </InputAdornment>
                  ),
                }}
              />
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={state.apellidos}
                onChange={handleChange}
                label="Apellidos"
                name="apellidos"
                placeholder="Ingrese sus apellidos"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiUserLine />
                    </InputAdornment>
                  ),
                }}
              />
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={state.user}
                onChange={handleChange}
                label="Usuario"
                name="user"
                placeholder="Ingrese su usuario"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiUserLine />
                    </InputAdornment>
                  ),
                }}
              />
              <CssTextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                name="contrasena"
                label="Contraseña"
                value={state.contrasena}
                onChange={handleChange}
                type="password"
                placeholder="Ingrese su contraseña"
                autoComplete="current-password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiLockPasswordLine />
                    </InputAdornment>
                  ),
                }}
              />

              <Button className={classes.btn_login} onClick={handleRegister}>Registrar</Button>
            </Grid>
            {
              alert.status &&
              <Alert severity={alert.type}>
              <AlertTitle>{alert.message1}</AlertTitle>
              {alert.message} — <strong>Revise esto!</strong>
            </Alert>
            }
          </form>
          </>
          }
        </Grid>
      </Grid>
    </main>
  );
};
export default AddAdmin;
