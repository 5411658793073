import { IconButton, Typography, Grid } from '@material-ui/core'
import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel';
import FormProduct from '../components/FormProduct';

const useStyles = makeStyles((theme)=>({
    content:{
        flexGrow:1,
        padding:theme.spacing(5)
      },
      formControl:{
        width:'250px',
        color:'#02AAB0',
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#02AAB0",
          borderWidth:'3px'
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#02AAB0",
          borderWidth:'3px'
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#02AAB0",
          borderWidth:'3px'
        },
    },
    inputfile:{
        padding:'30px',
        // backgroundColor:'rgba(47, 176, 193, 0.51);',
        color:'black',
        borderRadius:'10px',
        cursor:'pointer',
        border:'none',
        outline:'none'

    },
    
}))

const AddProduct = () => {
    const classes = useStyles()
    return (
      <div className={classes.content}>
        <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
          <Grid item>
            <IconButton color="primary">
              <CancelIcon color="primary" fontSize="large"></CancelIcon>
            </IconButton>
          </Grid>
        </Grid>
        <Grid container style={{ display: "flex", width: "100%" }}>
          <Grid item sm={12}>
            <Typography variant="h3" style={{ textAlign: "center" }}>
              Agregar Producto
            </Typography>
          </Grid>
        </Grid>
        <FormProduct></FormProduct>
      </div>
    );
}

export default AddProduct
