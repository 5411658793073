import {useEffect, useState, useRef} from 'react'
import axios from 'axios';
import {url} from '../data/url'

export const useCategory = () => {

    const isMounted =useRef(true)
    const [category, setCategory] = useState({
        categories:[]
    })
    useEffect(() => {
        
        return () => {
            isMounted.current =false
        }
    }, [])
    useEffect(  () => {

        axios
        .get(`${url}admin/categories`)
        .then(res =>{
            if(isMounted.current){
                console.log(res)
                setCategory(
                    {
                      ...category,
                      categories:res.data
                    })
            } 
        })
        .catch(err =>{
          // console.log(err)
        })
        
  }, [])
  
  return category
}



