import { IconButton, Popover, Typography } from '@material-ui/core';
import React from 'react'
import NoteIcon from '@material-ui/icons/Note';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));


const PopoverTabel = ( props ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const {row} = props

    return (
        <>
        <IconButton  variant="contained" color="primary" onClick={handleClick}>
                          <NoteIcon></NoteIcon>
                        </IconButton>
                          <Popover
                 
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            
                          >
                              <Typography className={classes.typography}>{row}</Typography>
                          </Popover>
                          </>
    )
}

export default PopoverTabel
