import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputBase, NativeSelect, Snackbar, TextField, Typography } from '@material-ui/core'
import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import {url} from '../data/url'
import CancelIcon from "@material-ui/icons/Cancel";
import CategoryComponent from '../components/CategoryComponent'
import Axios from "axios";
import { Alert, AlertTitle } from '@material-ui/lab';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme)=>({
  content:{
      flexGrow:1,
      paddingBottom:theme.spacing(3)
  },
  btn_category: {
    backgroundColor: "#02AAB0",
    color: "white",
    fontSize: "18px",
    borderRadius: "20px",
    width: "175px",
    padding:'10px',
    marginRight: "75px",
    "&:hover": {
      backgroundColor: "#00858a",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const ButtonUpdateProducts = () => {
    const history = useHistory()
    const classes = useStyles()

    const [addcaegory, setAddcategory] = useState({
        Nombre: '',
        Portada:'',
        Condicion: 1
      })

    const [message,setMessage] = useState({
      type:'',
      message1:'',
      message2:'',
      status:false
    })

    const [open2, setOpen2] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const timer = useRef()
    
      const handleCategory =  () =>{
        Axios
        .post(`${url}admin/categories`,addcaegory)
        .then(res =>{
           console.log(res.data);
           if(res.data.success === 1) {
            setMessage({
              ...message,
              type:'success' ,
              message1:'Exitoso',
              message2:res.data.message,
              status:true
            })
            setOpen2(true)

            setOpen1(true)
            timer.current = setTimeout(() => {
              
              history.push('/category')
            }, 1500);
           }else{
            setMessage({
              ...message,
              type:'warning' ,
              message1:'Rechazado',
              message2:res.data.message,
              status:true
            })
            setOpen2(true)
            timer.current = setTimeout(() => {
              setMessage({
                ...message,
                status:false
              })
              setOpen2(false)
            }, 2000);
           }
        })
      }
      console.log(addcaegory)

      const [open, setOpen] = React.useState(false);

       const handleClickOpen = () => {
         setOpen(true);
       };
    
       const handleClose = () => {
         setOpen(false);
       };


     

      
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          className={classes.btn_category}
          onClick={handleClickOpen}
        >
          <Typography color="secondary" style={{ fontSize: "11px" }}>
            Agregar Categoría
          </Typography>
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogContent>
            <main style={{ margin: "5px 10px 15px 10px" }}>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    history.push("/category");
                  }}
                >
                  <CancelIcon color="primary" style={{ fontSize: "32px" }} />
                </IconButton>
              </Grid>

              <Typography
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Agregar Categoria
              </Typography>

              <Typography
                style={{
                  fontSize: "24px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  marginLeft: "150px",
                }}
              >
                {" "}
                Datos de Categoría
              </Typography>

              <CategoryComponent
                setAddcategory={setAddcategory}
                addcaegory={addcaegory}
              ></CategoryComponent>

              <Grid item align="center">
                <Button
                  className={classes.btn_category}
                  onClick={handleCategory}
                >
                  Agregar
                </Button>
                <Backdrop className={classes.backdrop} open={open1} >
                <CircularProgress color="inherit" />
                </Backdrop>
              </Grid>
              {
              message.status &&
              <Snackbar open={open2} autoHideDuration={6000} >
              <Alert onClose={handleClose} severity={message.type}>
              {message.message2}
              </Alert>
            </Snackbar>
              }
            </main>
            
          </DialogContent>
        </Dialog>
      </>
    );
}

export default ButtonUpdateProducts;
