import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputBase, NativeSelect, Snackbar, TextField, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import FormProduct from '../components/FormProduct';
import { useHistory } from 'react-router-dom';
import CachedIcon from '@material-ui/icons/Cached';
import Axios from 'axios';
import {url} from '../data/url'
import { useRef } from 'react';
import { Alert } from '@material-ui/lab';
import CircularProgress from "@material-ui/core/CircularProgress";



const useStyles = makeStyles((theme)=>({
  content:{
      flexGrow:1,
      paddingBottom:theme.spacing(4)
    },
    formControl:{
      width:'250px',
      color:'#02AAB0',
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#02AAB0",
        borderWidth:'3px'
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#02AAB0",
        borderWidth:'3px'
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#02AAB0",
        borderWidth:'3px'
      },
  },
  inputfile:{
      padding:'30px',
      // backgroundColor:'rgba(47, 176, 193, 0.51);',
      color:'black',
      borderRadius:'10px',
      cursor:'pointer',
      border:'none',
      outline:'none'

  },
  btn_category: {
    backgroundColor: "#02AAB0",
    color: "white",
    fontSize: "18px",
    borderRadius: "20px",
    width: "200px",
    "&:hover": {
      backgroundColor: "#00858a",
    },
    "&:active":{
      position: 'relative',
      top:'2px',
    }
  },
  button:{
    borderRadius:'10px',
    padding:'20px',
    margin:'0px 10px'
},
backdrop: {
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
},
  
}))

const ButtonUpdateProducts = () => {
    const history = useHistory()
    const classes = useStyles()

    
      const [open, setOpen] = React.useState(false);


      const [message,setMessage] = useState({
        type:'',
        message1:'',
        message2:'',
        status:false
      })
      
      const timer = useRef()

      const [open2, setOpen2] = React.useState(false);
      const [open1, setOpen1] = React.useState(false);


       const handleClickOpen = () => {
         setOpen(true);
       };
    
       const handleClose = () => {
         setOpen(false);
       };


       const [addproduct,setAddproduct] = useState({
        nombre_producto:'',
        precio_producto:'',
        stock_producto:'',
         descripcion_producto:'',
         avatar_producto:'',
         IdCategoria:''

       })

       console.log(addproduct)

       const handleChange = (e) =>{
        setAddproduct({
          ...addproduct,
          [e.target.name]:e.target.value
        })
       }


       const handleProduct =  () =>{
        Axios
        .post(`${url}admin/products`,addproduct)
        .then(res =>{
           console.log(res);
           if(res.data.success === 1) {
            setMessage({
              ...message,
              type:'success' ,
              message1:'Exitoso',
              message2:res.data.message,
              status:true
            })
            setOpen2(true)

            setOpen1(true)
            timer.current = setTimeout(() => {
              
              history.push('/product')
            }, 1500);
           }else{
            setMessage({
              ...message,
              type:'warning' ,
              message1:'Rechazado',
              message2:res.data.message,
              status:true
            })
            setOpen2(true)
            timer.current = setTimeout(() => {
              setMessage({
                ...message,
                status:false
              })
              setOpen2(false)
            }, 2000);
           }
        })
      }

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <Typography
            color="secondary"
            variant="subtitle1"
            style={{ fontWeight: "100" }}
          >
            Agregar Producto
          </Typography>
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogContent>
            <div className={classes.content}>
              <Grid
                container
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item>
                  <IconButton color="primary" onClick={handleClose}>
                    <CancelIcon
                      color="primary"
                      style={{ fontSize: "30px" }}
                    ></CancelIcon>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container style={{ display: "flex", width: "100%" }}>
                <Grid item sm={12}>
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    Agregar Producto
                  </Typography>
                </Grid>
              </Grid>
              <FormProduct setAddproduct={setAddproduct} addproduct={addproduct} handleChange={handleChange} add={true}></FormProduct>
              <Grid
                item
                align="center"
                style={{ marginTop: "15px", marginBottom: "25px" }}
              >
                <Button className={classes.btn_category} onClick={handleProduct}>Agregar</Button>
                <Backdrop className={classes.backdrop} open={open1} >
                <CircularProgress color="inherit" />
                </Backdrop>
                
              </Grid>
              {
              message.status &&
              <Snackbar open={open2} autoHideDuration={6000} >
              <Alert onClose={handleClose} severity={message.type}>
              {message.message2}
              </Alert>
            </Snackbar>
              }
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
}

export default ButtonUpdateProducts;
