import React from 'react'
import { DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useState } from 'react';
import es from "date-fns/locale/es"
import DateFnsUtils from '@date-io/date-fns';
import { useEffect } from 'react';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { FormatListBulleted } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    underline: {
        "&&&:before": {
          borderBottom: "none"
        },
        "&&:after": {
          borderBottom: "none"
        }
      },
    input:{
        width:'100%',
        color:'white',
        border:'2px solid #02AAB0',
        borderRadius:'10px',
        padding:'10px',
        marginTop:'10px',
        marginBottom:'10px',
        "&:hover": {
            borderBottom:'none',
          },
    }


})); 
const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#02AAB0',
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
        //   backgroundColor:'red',
        //   color: "white",
        },
      },
      MuiPickersDay: {
        day: {
          color: 'black',
        },
        daySelected: {
          backgroundColor: '#02AAB0',
          '&:hover': {
            backgroundColor:'#02AAB0',
          },
        },
        dayDisabled: {
          color: 'black',
        },
        current: {
          color: 'black',
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: 'black',
        },
      },
      MuiPickersClock:{
        backgroundColor:'red',
        clock: {
            backgroundColor: '#e8e6e6'
          },
          pin:{
            color:'#02AAB0',
            backgroundColor:'#02AAB0',
          },
          pointer:{
            color:'#02AAB0',
            backgroundColor:'#02AAB0',
          }
      },
      MuiPickersClockPointer:{
          pointer:{
            color:'#02AAB0',
            backgroundColor:'#02AAB0',
          },
          thumb:{
              border:'14px solid #02AAB0',
              backgroundColor:'#02AAB0',
          },
          noPoint:{
            backgroundColor:'#02AAB0',
          },
      },
      MuiButton:{
          textPrimary:{
              color:'#02AAB0'
          }
      }
    },
  });
const InputDateInit = (props) => {
    const classes = useStyles();
    const {offer,setoffer} = props
    const [clearedDate, handleClearedDateChange] = useState(null);
    const [selectedDate, setSelectdate] = useState(new Date());

    const handleChange = (date) => {
        setSelectdate(date)
    }


    useEffect(() => {

        const mes = selectedDate.getMonth()+1
        const dia = selectedDate.getDate()
        const año = selectedDate.getFullYear()
        const hora = selectedDate.getHours()
        const minutos = selectedDate.getMinutes()
        const segundos = selectedDate.getSeconds()
        console.log(selectedDate.getSeconds())
        const fecha_inicio =`${año}-${mes}-${dia} ${hora}:${minutos}:${segundos}`

        setoffer({
            ...offer,
            fecha_inicio:fecha_inicio
        })
    }, [selectedDate])

    return (
        <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider  utils={DateFnsUtils}  locale={es}>
        <KeyboardDateTimePicker
        value={selectedDate}
        // onChange={handleDateChange}
        // onError={console.log}
        ampm={false}
        minDate={new Date()}
        format="yyyy/MM/dd H:mm:ss"
        onChange={handleChange}
        className={classes.input}
        InputProps={{ disableUnderline: true }}
      />
      </MuiPickersUtilsProvider>
      </ThemeProvider>
    )
}

export default InputDateInit
