import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CardMedia,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import CachedIcon from '@material-ui/icons/Cached';
import DeleteIcon from '@material-ui/icons/Delete';
import { url } from "../data/url";
import { useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useRef } from "react";

import ButtonUpdateCategory from '../components/ButtonUpdateCategory'


const useStyles = makeStyles((theme) => ({
  root: {},
  sub: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    "@media (max-width: 800px)": {
      fontSize: "12px",
    },
  },

  container_categoria: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    padding: "0px",
    boxShadow: "10px 10px 14px -10px rgba(0,0,0,0.45)",
    height: "auto",
    margin: "auto",
    width: "90%",
    marginTop: "30px",
  },

  centrar: {
    display: "flex",
    justifyContent: "center",
  },
  img_product: {
    height: "auto",
    width: "150px",
    borderRadius: "15px",
    margin: "15px",
    maxWidth: "100%",
    maxHeight: "100px",
    "@media (max-width: 600px)": {
      width: "60px",
      height: "40px",
      borderRadius: "5px",
    },
  },
  btn_icons:{
    minWidth: '1px',
    margin: 'auto',
    paddingRight: '20px',
    paddingLeft: '20px',
      
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const CategoryCard = (props) => {
  const classes = useStyles();

  const [auth, setAuth] = React.useState(props.condicion === "1" ? true : false);
  console.log(auth);

  const [catput, setCatput] = useState({
    funcion:"1",
    IdCategoria:props.id_Cat,
    Condicion:auth == true ? "0" : "1"
  })

  const handleChange = (event) => {
    setAuth(event.target.checked);
    setCatput({
      ...catput,
      Condicion:auth == true ? "1" : "0"
    })


    Axios
    .put(`${url}admin/categories`,catput)
    .then(res=>{
      console.log(res)
      
    })
    
  };

  const history = useHistory()

  const handleDelete = () =>{
    
    const url1 = url
      async function postData(url = `${url1}admin/categories`, data = {
        IdCategoria:props.id_Cat
      }) {
  
        const response = await fetch(url, {
          method: 'DELETE', 
          mode: 'cors', 
          cache: 'no-cache', 
          credentials: 'same-origin', 
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow', 
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(data) 
        });
        return response.json(); 
      }

      postData()
        .then(data => {
          console.log(data); 
          setOpen(!open);
          timer.current = setTimeout(()=>{
            history.push('/category')
          },2500)
        });
            
  }
    const timer = useRef()

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
      setOpen(false);
    };


  return (
    <Grid container className={classes.container_categoria}>
      <Grid sm={2} xs={2}>
        <Typography className={classes.sub}>{props.id_Cat}</Typography>
      </Grid>
      <Grid sm={3} xs={3} className={classes.centrar}>
        <CardMedia
          component="img"
          className={classes.img_product}
          image={props.portada_producto}
        ></CardMedia>
      </Grid>
      <Grid sm={2} xs={2}>
        <Typography className={classes.sub}>{props.nombre_producto}</Typography>
      </Grid>
      <Grid sm={3} xs={3}>
        <FormGroup>
          <FormControlLabel
            style={{ margin: "auto" }}
            control={
              <Switch
                checked={auth}
                onChange={handleChange}
                aria-label="category switch"
                color="primary"
              />}
           
          />
        </FormGroup>
      </Grid>
      <Grid sm={2} xs={2} style={{display:'flex'}}>

          <ButtonUpdateCategory id={props.id_Cat}/>
        
        <Button className={classes.btn_icons} onClick={handleDelete}>
          <DeleteIcon color="primary"/>
        </Button>
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </Grid>
    </Grid>
  );
};

export default CategoryCard;
