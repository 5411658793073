import React, { useEffect, useState } from 'react'
import {makeStyles} from '@material-ui/core/styles'
import { Button, Grid, InputBase, InputLabel, MenuItem, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import ProductoCard from '../components/ProductoCard';
import { useHistory } from 'react-router-dom';
import ProductHeader from '../components/ProductHeader';
import { useCategory } from '../hooks/useCategory';
import ButtonAddProducts from '../components/ButtonAddProducts'
import Axios from 'axios';
import { useRef } from 'react';
import {url} from '../data/url'
import LoopIcon from '@material-ui/icons/Loop';

const useStyles = makeStyles((theme)=>({
    content:{
        flexGrow:1,
        padding:theme.spacing(5),
        minHeight:'100vh'
      },
      button:{
          borderRadius:'10px',
          padding:'20px',
          margin:'0px 10px'
      },
      formControl:{
          width:'250px',
          color:'#02AAB0',
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#02AAB0",
            borderWidth:'3px'
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#02AAB0",
            borderWidth:'3px'
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#02AAB0",
            borderWidth:'3px'
          },
      },
      search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#D8D8D8',
        '&:hover': {
          backgroundColor: '#D8D8D8',
        },
        width: '300px',
        marginLeft:'10px',
        height:'35px',
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {

        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '80%',
        display:'flex',
        alignItems:'center'
      },

}))
const Products = () => {
    const history =useHistory()
    const classes = useStyles()

    const {categories} = useCategory()
    
    const [namesearch,setNamesearch] = useState("")

    const isMounted =useRef(true)
    //estado para productos por busqueda
    const [product, setproduct] = useState({
      products:[]
    })

    useEffect(()=>{
      
      Axios
      .get(`${url}admin/products`)
      .then(res =>{
          console.log(res)
          if(isMounted.current){
            setproduct(
                  {
                    ...product,
                    products:res.data
                  })
          }
          
          
      })
      .catch(err =>{
        // console.log(err)
      })
    },[])


    const [idcat, setidcat] = useState('')

    // console.log('cat',idcat)

    const handleChange = (e) =>{

      setidcat(e.target.value) 
      const productname = product.products.filter(
        (productname) =>
          productname.IdCategoria === e.target.value
      );
      console.log(productname)

      setproduct({
        ...product,
        products: productname,
      });

    }



    const handleChangename = (e) => {
      setNamesearch(e.target.value)
    }

    console.log(namesearch)

    const handlePress = (e) => {

      if (e.key === "Enter") {
        if (namesearch !== "") {
          const productname = product.products.filter(
            (productname) =>
              productname.nombre_producto.toLowerCase() === namesearch
          );
          console.log(productname);
          setproduct({
            ...product,
            products: productname,
          });
          setNamesearch("");
        } else {
          Axios.get(`${url}admin/products`).then((res) => {
            console.log(res);
            if (isMounted.current) {
              setproduct({
                ...product,
                products: res.data,
              });
            }
          });
        }
      }

    }

    console.log(product.products)

    const handleReset = ( ) => {
      Axios.get(`${url}admin/products`).then((res) => {
        console.log(res);
        if (isMounted.current) {
          setproduct({
            ...product,
            products: res.data,
          });
        }
      });
    }
  
    return (
      <div className={classes.content}>
        <Typography variant="h3">Productos</Typography>
        <br></br>
        <Grid container>
          <Grid item sm={6} xs={12}>
            {/* <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={()=>{
                history.push("/addproduct")
              }}
            >
              <Typography
                color="secondary"
                variant="subtitle1"
                style={{ fontWeight: "700" }}
              >
                Agregar Producto
              </Typography>
            </Button> */}
            <ButtonAddProducts></ButtonAddProducts>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={()=>{
                history.push("/offer")
              }}
            >
              <Typography
                color="secondary"
                variant="subtitle1"
                style={{ fontWeight: "700" }}
              >
                Ir al panel de ofertas
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            style={{ 
              display: "flex", 
              alignItems: "center"}}
            sm={6}
            xs={12}
          >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Escoger Categoria
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={idcat}
                onChange={handleChange}
                label="Categorias"
                style={{ backgroundColor: "white" }}
              >
                <MenuItem value={0}>
                  <em>Escoger una Categoria</em>
                </MenuItem>
                {
                  categories.map((item)=>{
                  return (
                    
                  <MenuItem value={item.IdCategoria} >{item.Nombre}</MenuItem>
                  )
                  })
                }
              </Select>
            </FormControl>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon color="primary" />
              </div>
              <InputBase
                placeholder="Buscar..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                value={namesearch}
                onChange={handleChangename}
                onKeyPress={handlePress}
              />
            </div>
            <Button color="primary" variant="contained" onClick={handleReset}>
              <LoopIcon color="secondary"/>
            </Button>
          </Grid>
        </Grid>
      <ProductHeader></ProductHeader>  
      {
        product.products.length > 0 &&
        product.products.map(item =>{
          return (
          <ProductoCard 
            item={item}
            ></ProductoCard>)
        })
      }

      {/* {
        !initial &&
        
      } */}
      
      </div>
    );
}

export default Products
