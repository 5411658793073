import { Button, IconButton, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import Axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import {url} from '../data/url'
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
const useStyles = makeStyles((theme) => ({
  
    typography: {
      padding: theme.spacing(2),
      color:'#02AAB0',
      textAlign:'center'
    },
    div_box:{
        width: '550px',
        margin: '5px 50px 5px 50px',
        display: 'flex',
        
    },
    txt_t1:{
      fontWeight:'bold',
    },
    txt_t2:{
      fontWeight:'lighter',
      color:'#575757'
    },
    div_item:{
      width:'50%',
    },
    table_container:{
      margin:'5px 20px 35px 20px',
    }
  }));


const PopoverTabel = ( {id}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [detail, setDetail] = useState([])

  useEffect(() => {
    Axios
    .post(`${url}admin/ventas_response`,{
        id: id
    })
    .then(res =>{
     setDetail(res.data)
    })
 }, [])



 const [products, setProducts] = useState([])
 
    useEffect(() => {
       Axios
       .post(`${url}admin/ventas_detalle`,{
           id: id
       })
       .then(res =>{
        setProducts(res.data)
       })
    }, [])

    const total = products.reduce((total1, product) => {
    return   total1+parseInt(product.subtotal)
    },0)

   


    return (
        <>
        <Button  variant="contained" color="primary" style={{color:'white',fontSize:'10px'}} onClick={handleClick}>
                        {id}
                        </Button>
                          <Popover
                 
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            
                          >
       <div style={{width:'600px',margin:'5px 0px 50px 0px'}}>
          <div style={{display:'flex',justifyContent:'flex-end',marginRight:'10px'}}>
                  <IconButton
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    style={{color:'#02AAB0',}}
                  >
                    <CancelIcon style={{fontSize:'30px'}}/>
                  </IconButton> 
                  </div>
                              <Typography className={classes.typography}>Informacion de la compra</Typography>

                                  {/* ======================================================================= 
                                        INFORMACION DE LA COMPRA
                                   ======================================================================= 
                                  */}
        
                    <div>
                    <div className={classes.div_box}>
                      <div className={classes.div_item}>
                        <Typography className={classes.txt_t1}>Número de compra</Typography>
                      </div>
                      <div className={classes.div_item}>
                      <Typography className={classes.txt_t2}>{id}</Typography>
                      </div>
                    </div>

                    <div className={classes.div_box}>
                      <div className={classes.div_item}>
                        <Typography className={classes.txt_t1}>Fecha y Hora</Typography>
                      </div>
                      <div className={classes.div_item}>
                      <Typography className={classes.txt_t2}>{detail.fechahora}</Typography>
                      </div>
                    </div>

                    <div className={classes.div_box}>
                      <div className={classes.div_item}>
                        <Typography className={classes.txt_t1}>Cliente</Typography>
                      </div>
                      <div className={classes.div_item}>
                      <Typography className={classes.txt_t2}>{detail.cliente}</Typography>
                      </div>
                    </div>

                    <div className={classes.div_box}>
                      <div className={classes.div_item}>
                        <Typography className={classes.txt_t1}>Correo</Typography>
                      </div>
                      <div className={classes.div_item}>
                      <Typography className={classes.txt_t2}>{detail.correo}</Typography>
                      </div>
                    </div>

                    <div className={classes.div_box}>
                      <div className={classes.div_item}>
                        <Typography className={classes.txt_t1}>Pagado con</Typography>
                      </div>
                      <div className={classes.div_item}>
                      <Typography className={classes.txt_t2}>{detail.marca}</Typography>
                      </div>
                    </div>

                    <div className={classes.div_box}>
                      <div className={classes.div_item}>
                        <Typography className={classes.txt_t1}>Respuesta</Typography>
                      </div>
                      <div className={classes.div_item}>
                      <Typography className={classes.txt_t2}>{detail.respuesta}</Typography>
                      </div>
                    </div>

                    <div className={classes.div_box}>
                      <div className={classes.div_item}>
                        <Typography className={classes.txt_t1}>ID Transaccion</Typography>
                      </div>
                      <div className={classes.div_item}>
                      <Typography className={classes.txt_t2}>{detail.idtransaccion}</Typography>
                      </div>
                    </div>
                    </div>
          
        
                    
                            
                            {/* ======================================================================= */}

                            <Typography className={classes.typography}>Detalle de la compra</Typography>
                            <div className={classes.table_container}>
                            <TableContainer >
                                  <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="center">Cantidad</TableCell>
                                        <TableCell align="center">Producto</TableCell>
                                        <TableCell align="center">Subtotal</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {

                                  products.map(item =>{
                                    return (

                                      <TableRow >
                                      <TableCell align="center">{item.cantidad}</TableCell>
                                      <TableCell align="center">{item.nombre}</TableCell>
                                    <TableCell align="center">S/{item.subtotal}</TableCell>
                                    </TableRow>
                                    )
                                  })
                                         
                                      }
                                      
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                </div>

                                <div className={classes.div_box}>
                              <div className={classes.div_item} >
                                <Typography className={classes.txt_t1} style={{textAlign:'center'}}>Total</Typography>
                              </div>
                              <div className={classes.div_item}>
                              <Typography className={classes.txt_t2}>S/{total}</Typography>
                              </div>
                            </div>

                              </div>
                             
                          </Popover>
                          </>
    )
}

export default PopoverTabel
