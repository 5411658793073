import { AppBar, Button, IconButton, Toolbar, Typography } from '@material-ui/core'
import React from 'react'
import logo from '../assets/EMILIMA.png'
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core'
import { AdminContext } from '../context/AdminContext';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme)=>({
    root: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
        display:'flex',
        alignItemsc:'center'
      },
      appbar:{
          width:`calc(100%-${240}px)`
      }
}))
const Appbar = (props) => {

    const history = useHistory()

    const {open , setOpen} =props
    const classes = useStyles()

    const handleOpen = () =>{
        setOpen(true)
    }

    const {dispatch} = useContext(AdminContext)

    const logout = () =>{

      history.replace("/login")
      dispatch({
        type:'logout'
      })
     
    }
    return (
        <div className={classes.root}>
        <AppBar position="static" color="secondary" className={classes.appbar}>
          <Toolbar>
            <IconButton color="inherit" aria-label="menu" onClick={handleOpen}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            <img src={logo} style={{width:'185px'}} ></img>
            </Typography>
            <IconButton color="inherit" onClick={logout}><ExitToAppIcon></ExitToAppIcon></IconButton>
          </Toolbar>
        </AppBar>
      </div>
    );
}

export default Appbar
