import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import firebase from 'firebase'


firebase.initializeApp({
  apiKey: "AIzaSyCfl1hrFPyItMuVKNihvRpNd_L3-6KK17Q",
  authDomain: "tienda-virtual-emilima.firebaseapp.com",
  databaseURL: "https://tienda-virtual-emilima.firebaseio.com",
  projectId: "tienda-virtual-emilima",
  storageBucket: "tienda-virtual-emilima.appspot.com",
  messagingSenderId: "449787496878",
  appId: "1:449787496878:web:ddf146c33a1125ca6e603f"
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

