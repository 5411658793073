import  { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import {url} from '../data/url'

export const useProducts = () => { 
    // console.log(idcat);
    const isMounted =useRef(true)
    const [product, setProduct] = useState({
        products:[]
    })

    useEffect(() => {
        
        return () => {
            isMounted.current =false
        }
    }, [])
    
    useEffect(  () => {
        

        // axios
        // .post(`${url}ecommerce/products`,{
        //     id: idcat
        // })
        // .then(res =>{
        //     console.log(res)
        //     if(isMounted.current){
        //         setProduct(
        //             {
        //               ...product,
        //               products:res.data
        //             })
        //     }
            
            
        // })
        // .catch(err =>{
        //   // console.log(err)
        // })

        axios
        .get(`${url}admin/products`)
        .then(res =>{
            console.log(res)
            if(isMounted.current){
                setProduct(
                    {
                      ...product,
                      products:res.data
                    })
            }
            
            
        })
        .catch(err =>{
          // console.log(err)
        })
        
  }, [])
  
  return product
}


