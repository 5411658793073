import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>({
    sub: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        "@media (max-width: 800px)": {
          fontSize: "12px",
        },
      },
    
      container_categoria: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        padding: "0px",
        boxShadow: "10px 10px 14px -10px rgba(0,0,0,0.45)",
        height: "60px",
        margin: "auto",
        width: "90%",
        marginTop: "30px",
      },
}))
const CategoryHeader = () => {

    const classes = useStyles()
    return (
        <Grid container className={classes.container_categoria}>
        <Grid sm={2} xs={2}>
          <Typography className={classes.sub}>ID</Typography>
        </Grid>
        <Grid sm={3} xs={3}>
          <Typography className={classes.sub}>Portada</Typography>
        </Grid>
        <Grid sm={2} xs={2}>
          <Typography className={classes.sub}>Nombre</Typography>
        </Grid>
        <Grid sm={3} xs={3}>
          <Typography className={classes.sub}>Habilitar/Desactivar</Typography>
        </Grid>
        <Grid sm={2} xs={2}>
          <Typography className={classes.sub}>Acciones</Typography>
        </Grid>
      </Grid>
    )
}

export default CategoryHeader
