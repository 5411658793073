import React from "react";
import PropTypes from "prop-types";
import {  makeStyles,withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Button, IconButton, Typography } from "@material-ui/core";
import NoteIcon from '@material-ui/icons/Note';
import VentaCompletada from "./VentaCompletada";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';  
import PopoverInfoSale from "./PopoverInfoSale";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#02AAB0',
    color: 'white',
    border:'1px solid white',
  },
  body: {
    fontSize: 12,
    backgroundColor: '#e8e8e8',
    border:'1px solid #cac7c7a8',
  },

}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'theme.palette.action.hover',
    },
  },
}))(TableRow);

function createData(id,dni_ruc,cliente,celular,email,fecha) {
  return { id,dni_ruc,cliente,celular,email,fecha};
}

// const rows = [
//   createData(10426, 44888648,"Cliente",995493234,"asdas@gmail.com","24/09/2020 14:18:29"),
//   createData(10451, 23658648,"B",995495234,"beasdas@gmail.com","21/04/2020 14:18:29"),
//   createData(10732, 44345643,"Añañin",995493777,"csad@gmail.com","19/04/2020 14:18:29"),
// ];

// ================================================
// FUNCIONES PARA EL ORDENADO DE LA TABLA

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// ================================================


const headCells = [
  { id: "id",numeric: true,disablePadding: false,label: "ID"},
  { id: "dni_ruc", numeric: true, disablePadding: false, label: "DNI/RUC" },
  { id: "cliente", numeric: false, disablePadding: false, label: "CLIENTE" },
  { id: "celular", numeric: true, disablePadding: false, label: "CELULAR" },
  { id: "email", numeric: false, disablePadding: false, label: "CORREO" },
  { id: "fecha", numeric: false, disablePadding: false, label: "FECHA" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
          
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontSize:'12px !important',
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);


  const {salesfailed} =props

  const sales1= salesfailed.map(item =>{
    return createData(item.IdPedido,item.nro_documento,item.nombres,item.telf_ped,item.email_cliente,item.fecha_pedido)
  })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, sales1.length - page * rowsPerPage);

    const dia = new Date()

    const mes = dia.getMonth()
    const mes1 = mes +1 
    const año = dia.getFullYear()

    const fecha1 = `Ventas Fallidas ${mes1}/${año}`

    const printDocument1 =() => {  
      const input = document.getElementById('pdfdiv');  
      html2canvas(input)  
        .then((canvas) => {  
          var imgWidth = 295;  
          var pageHeight = 290;  
          var imgHeight = canvas.height * imgWidth / canvas.width;  
          var heightLeft = imgHeight;  
          const imgData = canvas.toDataURL('image/png');  
          const pdf = new jsPDF('l', 'mm', 'a4',)  
          var position = 0;  
          var heightLeft = imgHeight;  
          pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);  
          pdf.save(fecha1);  
        });  
    }  
  return (
    <div className={classes.root}>
      <VentaCompletada sales={salesfailed} printDocument1={printDocument1} status='failed'></VentaCompletada>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            id='pdfdiv'
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={sales1.length}
            />
            <TableBody>
              {stableSort(sales1, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      // hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        align="center"
                        scope="row"
                        padding="none"
                      >
                       <PopoverInfoSale id={row.id}></PopoverInfoSale>
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.dni_ruc}</StyledTableCell>
                      <StyledTableCell align="center">{row.cliente}</StyledTableCell>
                      <StyledTableCell align="center">{row.celular}</StyledTableCell>
                      <StyledTableCell align="center">{row.email}</StyledTableCell>
                      <StyledTableCell align="center">{row.fecha}</StyledTableCell>                  
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow >
                  <StyledTableCell colSpan={13} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          labelRowsPerPage="Filas por Página"
          count={sales1.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
