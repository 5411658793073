import React, { useEffect, useState } from 'react'
import {makeStyles} from '@material-ui/core/styles'
import { Box, Button, FormControlLabel, FormGroup, Grid, Switch, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TableVentaPending from '../components/TableVentaPending'
import {url} from '../data/url'
import Axios from 'axios';
import { FaBullseye } from 'react-icons/fa';

const useStyles = makeStyles((theme)=>({
    content:{
        flexGrow:1,
        padding:theme.spacing(5)
      },
      toolbar: theme.mixins.toolbar,
      main: {
        margin: "0px 150px 0px 150px",
        width: "70%",
      },
      title_mant: {
        fontSize: "35px",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: "25px",
      },
      grid_container: {
        width: "500px",
        margin: "auto",
        marginBottom:'20px',
      },
      grid_item: {
        textAlign: "center",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      grid_item_izq: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      },
}))
const Dashboard = () => {
  const history = useHistory()
    const classes = useStyles()
    
    const [sales, setSales] = useState([])

    const [ condicion , setCondicion] = useState("")

    
    const respuestamante = async () =>{
      let res = await Axios.get(`${url}admin/mantenimiento`);
      let  {config_tienda}  = res.data;
      setCondicion(config_tienda.condicion_abierto == 0 ? true : false);
    }

    console.log(`Cual es la ${condicion}`);
  
    const [catput, setCatput] = useState({
      condicion:condicion == true ? 0 : 1
      
    })
   console.log(catput.condicion);
  
    const handleChange = (event) => {
      setCondicion(event.target.checked);
      setCatput({
        ...catput,
        condicion:condicion == false ? 1 : 0
      })
      console.log(catput);
  
  
      Axios
      .put(`${url}admin/mantenimiento`,catput)
      .then(res=>{
        console.log(res)
      })
      
    };
  

    useEffect(() => {
        Axios
        .get(`${url}admin/ventas_pendientes`)
        .then(res=>{
            // console.log(res)
            // console.log(res.data)
            setSales(res.data)
        })
        respuestamante()
    },[])




    return (
      <>
        <div className={classes.content}>
          <div className={classes.toolbar}>
            <Typography variant="h3">Dashboard</Typography>
            <br></br>
            <div style={{ width: "100%"}}>
            <Grid container className={classes.grid_container}>
        <Grid item xs={6} className={classes.grid_item}>
          <Typography style={{fontSize:'25px'}}>Mantenimiento</Typography>
        </Grid>
        <Grid item xs={6} className={classes.grid_item}>
          <Typography  style={{fontSize:'25px'}}> OFF/ON</Typography>
        </Grid>
      </Grid>
      
      <Grid container className={classes.grid_container}>
        <Grid item xs={6} style={{justifyContent:'center'}} className={classes.grid_item_izq}>
          <Typography>TIENDA VIRTUAL</Typography>
        </Grid>
        <Grid item xs={6} className={classes.grid_item}>
        <FormGroup>
          <FormControlLabel
            style={{ margin: "auto" }}
            control={
              <Switch
                // checked={mante}
                // onChange={handleChange}
                aria-label="category switch"
                color="primary"              
                checked={condicion}
                onChange={handleChange}
              />}
            />
        </FormGroup>
        </Grid>
      </Grid>
            
             </div>
             <TableVentaPending sales={sales} setsales={setSales}></TableVentaPending>




{/* 
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                // css={{ maxWidth: 300 }}
              >
                <Box p={2} >
                  <Button style={{backgroundColor:'#2D9CDB',padding:'50px'}} variant="text" onClick={()=>{
                    history.push('/maintenance')
                  }}>
                    <Typography 
                    variant="h4"
                    style={{textShadow:'0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)'}}
                    color="secondary">Modo Mantenimiento</Typography>
                  </Button>
                </Box>
                <Box p={2} >
                <Button style={{backgroundColor:'#828282',padding:'50px'}}>
                    <Typography 
                    
                    variant="h4" 
                    style={{textShadow:'0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)'}}
                    color="secondary"> Añadir Categorias</Typography>
                  </Button>
                </Box>
                <Box p={2} >
                <Button style={{backgroundColor:'#BB6BD9',padding:'50px'}}>
                    <Typography 
                    variant="h4"
                    style={{textShadow:'0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)'}}
                    color="secondary">Añadir productos y ofertas</Typography>
                  </Button>
                </Box>
                <Box p={2} >
                <Button style={{backgroundColor:'#184D9D',padding:'50px'}}>
                    <Typography 
                    variant="h4"
                    style={{textShadow:'0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)'}}
                    color="secondary">Clientes</Typography>
                  </Button>
                </Box>
               
              </Box>
            */}
           
            
          </div>
        </div>
      </>
    );
}

export default Dashboard
